import React, { useEffect, useRef, useState } from "react";
import { Drawing, fetchDrawings } from "../../drawings/drawings";
import DrawingComponent from "./Drawing";
import Dibujo from "../../assets/textures/previews/dibujo.jpg";

const IbercajagltfDrawings = () => {
  // const apiBase = "https://cms-navidad.imascono.com/";
  const apiBase = "https://cms.espaciovirtualibercaja.es/";

  let drawingArray: Drawing[] = [];

  const sceneDrawings = useRef(drawingArray);

  const [drawingsLoaded, setDrawingsLoaded] = useState(false);

  // function fetchDrawings(limit: number = 32) {
  //   const endpoint = `${apiBase}/navidad_ibercaja/items/imagen?fields=${drawingFields}&sort=-created_on&limit=${limit}`;

  //   console.log("endpoint: ", endpoint);

  //   return multiDataProcessor(endpoint, processImage);
  // }

  useEffect(() => {
    // showContent(<UploadDrawingPanel />, true, false, () => console.log("bye!"));
    //showContent(<ImageText />, true, false, () => console.log("bye!"));
    // fetchDrawings()
    //   .then(([drawings, meta]) => {
    //     sceneDrawings.current = drawings;
    //   })
    //   .finally(() => {
    //     setDrawingsLoaded(true);
    //   });
    // fetchDrawings()
    //   .then((drawings) => {
    //     sceneDrawings.current = drawings;
    //     console.log(sceneDrawings.current.length);
    //     // drawings.forEach((drawing, index) => {
    //     //   SetTexture({ assetUrl: apiBase + drawing.assetUrl });
    //     //   console.log(apiBase + drawing.assetUrl);
    //     //   console.log("Texture loaded");
    //     //   // console.log(index);
    //     //   // console.log(drawing);
    //     //   // console.log(drawing.createdOn);
    //     //   // console.log(drawing.file);
    //     //   // console.log(drawing.assetUrl);
    //     // });
    //   })
    //   .finally(() => {
    //     setDrawingsLoaded(true);
    //   });
  }, []);

  return drawingsLoaded ? (
    <>
      <DrawingComponent
        // //geometry={nodes.Dibujo31.geometry}
        texturePath={
          sceneDrawings.current[31] !== undefined
            ? apiBase + sceneDrawings.current[31].assetUrl
            : Dibujo
        }
        position={[4.4567, 0.828, -17.1047]}
        rotation={[0, 0, 0]}
        scale={[1, 1, 1]}
        noFlipY={true}
      />
      <DrawingComponent
        // //geometry={nodes.Dibujo30.geometry}
        texturePath={
          sceneDrawings.current[30] !== undefined
            ? apiBase + sceneDrawings.current[30].assetUrl
            : Dibujo
        }
        position={[4.4567, 1.8075, -17.1047]}
        rotation={[0, 0, 0]}
        scale={[1, 1, 1]}
        noFlipY={true}
      />
      <DrawingComponent
        // //geometry={nodes.Dibujo29.geometry}
        texturePath={
          sceneDrawings.current[29] !== undefined
            ? apiBase + sceneDrawings.current[29].assetUrl
            : Dibujo
        }
        position={[4.4568, 2.7869, -17.1047]}
        rotation={[0, 0, 0]}
        scale={[1, 1, 1]}
        noFlipY={true}
      />
      <DrawingComponent
        // //geometry={nodes.Dibujo28.geometry}
        texturePath={
          sceneDrawings.current[28] !== undefined
            ? apiBase + sceneDrawings.current[28].assetUrl
            : Dibujo
        }
        position={[4.4568, 3.7663, -17.1047]}
        rotation={[0, 0, 0]}
        scale={[1, 1, 1]}
        noFlipY={true}
      />
      <DrawingComponent
        // //geometry={nodes.Dibujo27.geometry}
        texturePath={
          sceneDrawings.current[27] !== undefined
            ? apiBase + sceneDrawings.current[27].assetUrl
            : Dibujo
        }
        position={[3.1809, 0.828, -17.1047]}
        rotation={[0, 0, 0]}
        scale={[1, 1, 1]}
        noFlipY={true}
      />
      <DrawingComponent
        // //geometry={nodes.Dibujo26.geometry}
        texturePath={
          sceneDrawings.current[26] !== undefined
            ? apiBase + sceneDrawings.current[26].assetUrl
            : Dibujo
        }
        position={[3.1809, 1.8075, -17.1047]}
        rotation={[0, 0, 0]}
        scale={[1, 1, 1]}
        noFlipY={true}
      />
      <DrawingComponent
        // //geometry={nodes.Dibujo25.geometry}
        texturePath={
          sceneDrawings.current[25] !== undefined
            ? apiBase + sceneDrawings.current[25].assetUrl
            : Dibujo
        }
        position={[3.1809, 2.7869, -17.1047]}
        rotation={[0, 0, 0]}
        scale={[1, 1, 1]}
        noFlipY={true}
      />
      <DrawingComponent
        // //geometry={nodes.Dibujo24.geometry}
        texturePath={
          sceneDrawings.current[24] !== undefined
            ? apiBase + sceneDrawings.current[24].assetUrl
            : Dibujo
        }
        position={[3.1809, 3.7663, -17.1047]}
        rotation={[0, 0, 0]}
        scale={[1, 1, 1]}
        noFlipY={true}
      />
      <DrawingComponent
        // //geometry={nodes.Dibujo23.geometry}
        texturePath={
          sceneDrawings.current[23] !== undefined
            ? apiBase + sceneDrawings.current[23].assetUrl
            : Dibujo
        }
        position={[1.9051, 0.828, -17.1047]}
        rotation={[0, 0, 0]}
        scale={[1, 1, 1]}
        noFlipY={true}
      />
      <DrawingComponent
        // //geometry={nodes.Dibujo22.geometry}
        texturePath={
          sceneDrawings.current[22] !== undefined
            ? apiBase + sceneDrawings.current[22].assetUrl
            : Dibujo
        }
        position={[1.9051, 1.8075, -17.1047]}
        rotation={[0, 0, 0]}
        scale={[1, 1, 1]}
        noFlipY={true}
      />
      <DrawingComponent
        // //geometry={nodes.Dibujo21.geometry}
        texturePath={
          sceneDrawings.current[21] !== undefined
            ? apiBase + sceneDrawings.current[21].assetUrl
            : Dibujo
        }
        position={[1.9051, 2.7869, -17.1047]}
        rotation={[0, 0, 0]}
        scale={[1, 1, 1]}
        noFlipY={true}
      />
      <DrawingComponent
        // //geometry={nodes.Dibujo20.geometry}
        texturePath={
          sceneDrawings.current[20] !== undefined
            ? apiBase + sceneDrawings.current[20].assetUrl
            : Dibujo
        }
        position={[1.9051, 3.7663, -17.1047]}
        rotation={[0, 0, 0]}
        scale={[1, 1, 1]}
        noFlipY={true}
      />
      <DrawingComponent
        // //geometry={nodes.Dibujo19.geometry}
        texturePath={
          sceneDrawings.current[19] !== undefined
            ? apiBase + sceneDrawings.current[19].assetUrl
            : Dibujo
        }
        position={[0.6292, 0.828, -17.1047]}
        rotation={[0, 0, 0]}
        scale={[1, 1, 1]}
        noFlipY={true}
      />
      <DrawingComponent
        // //geometry={nodes.Dibujo18.geometry}
        texturePath={
          sceneDrawings.current[18] !== undefined
            ? apiBase + sceneDrawings.current[18].assetUrl
            : Dibujo
        }
        position={[0.6292, 1.8075, -17.1047]}
        rotation={[0, 0, 0]}
        scale={[1, 1, 1]}
        noFlipY={true}
      />
      <DrawingComponent
        // //geometry={nodes.Dibujo17.geometry}
        texturePath={
          sceneDrawings.current[17] !== undefined
            ? apiBase + sceneDrawings.current[17].assetUrl
            : Dibujo
        }
        position={[0.6292, 2.7869, -17.1047]}
        rotation={[0, 0, 0]}
        scale={[1, 1, 1]}
        noFlipY={true}
      />
      <DrawingComponent
        // //geometry={nodes.Dibujo16.geometry}
        texturePath={
          sceneDrawings.current[16] !== undefined
            ? apiBase + sceneDrawings.current[16].assetUrl
            : Dibujo
        }
        position={[0.6292, 3.7663, -17.1047]}
        rotation={[0, 0, 0]}
        scale={[1, 1, 1]}
        noFlipY={true}
      />
      <DrawingComponent
        // //geometry={nodes.Dibujo15.geometry}
        texturePath={
          sceneDrawings.current[15] !== undefined
            ? apiBase + sceneDrawings.current[15].assetUrl
            : Dibujo
        }
        position={[-0.6466, 0.828, -17.1047]}
        rotation={[0, 0, 0]}
        scale={[1, 1, 1]}
        noFlipY={true}
      />
      <DrawingComponent
        // //geometry={nodes.Dibujo14.geometry}
        texturePath={
          sceneDrawings.current[14] !== undefined
            ? apiBase + sceneDrawings.current[14].assetUrl
            : Dibujo
        }
        position={[-0.6466, 1.8075, -17.1047]}
        rotation={[0, 0, 0]}
        scale={[1, 1, 1]}
        noFlipY={true}
      />
      <DrawingComponent
        // //geometry={nodes.Dibujo13.geometry}
        texturePath={
          sceneDrawings.current[13] !== undefined
            ? apiBase + sceneDrawings.current[13].assetUrl
            : Dibujo
        }
        position={[-0.6466, 2.7869, -17.1047]}
        rotation={[0, 0, 0]}
        scale={[1, 1, 1]}
        noFlipY={true}
      />
      <DrawingComponent
        // //geometry={nodes.Dibujo12.geometry}
        texturePath={
          sceneDrawings.current[12] !== undefined
            ? apiBase + sceneDrawings.current[12].assetUrl
            : Dibujo
        }
        position={[-0.6466, 3.7663, -17.1047]}
        rotation={[0, 0, 0]}
        scale={[1, 1, 1]}
        noFlipY={true}
      />
      <DrawingComponent
        // //geometry={nodes.Dibujo11.geometry}
        texturePath={
          sceneDrawings.current[11] !== undefined
            ? apiBase + sceneDrawings.current[11].assetUrl
            : Dibujo
        }
        position={[-1.9225, 0.828, -17.1047]}
        rotation={[0, 0, 0]}
        scale={[1, 1, 1]}
        noFlipY={true}
      />
      <DrawingComponent
        // //geometry={nodes.Dibujo10.geometry}
        texturePath={
          sceneDrawings.current[10] !== undefined
            ? apiBase + sceneDrawings.current[10].assetUrl
            : Dibujo
        }
        position={[-1.9225, 1.8075, -17.1047]}
        rotation={[0, 0, 0]}
        scale={[1, 1, 1]}
        noFlipY={true}
      />
      <DrawingComponent
        // //geometry={nodes.Dibujo09.geometry}
        texturePath={
          sceneDrawings.current[9] !== undefined
            ? apiBase + sceneDrawings.current[9].assetUrl
            : Dibujo
        }
        position={[-1.9225, 2.7869, -17.1047]}
        rotation={[0, 0, 0]}
        scale={[1, 1, 1]}
        noFlipY={true}
      />
      <DrawingComponent
        // //geometry={nodes.Dibujo08.geometry}
        texturePath={
          sceneDrawings.current[8] !== undefined
            ? apiBase + sceneDrawings.current[8].assetUrl
            : Dibujo
        }
        position={[-1.9225, 3.7663, -17.1047]}
        rotation={[0, 0, 0]}
        scale={[1, 1, 1]}
        noFlipY={true}
      />
      <DrawingComponent
        // //geometry={nodes.Dibujo07.geometry}
        texturePath={
          sceneDrawings.current[7] !== undefined
            ? apiBase + sceneDrawings.current[7].assetUrl
            : Dibujo
        }
        position={[-3.1983, 0.828, -17.1047]}
        rotation={[0, 0, 0]}
        scale={[1, 1, 1]}
        noFlipY={true}
      />
      <DrawingComponent
        // //geometry={nodes.Dibujo06.geometry}
        texturePath={
          sceneDrawings.current[6] !== undefined
            ? apiBase + sceneDrawings.current[6].assetUrl
            : Dibujo
        }
        position={[-3.1983, 1.8075, -17.1047]}
        rotation={[0, 0, 0]}
        scale={[1, 1, 1]}
        noFlipY={true}
      />
      <DrawingComponent
        // //geometry={nodes.Dibujo05.geometry}
        texturePath={
          sceneDrawings.current[5] !== undefined
            ? apiBase + sceneDrawings.current[5].assetUrl
            : Dibujo
        }
        position={[-3.1983, 2.7869, -17.1047]}
        rotation={[0, 0, 0]}
        scale={[1, 1, 1]}
        noFlipY={true}
      />
      <DrawingComponent
        // //geometry={nodes.Dibujo04.geometry}
        texturePath={
          sceneDrawings.current[4] !== undefined
            ? apiBase + sceneDrawings.current[4].assetUrl
            : Dibujo
        }
        position={[-3.1983, 3.7663, -17.1047]}
        rotation={[0, 0, 0]}
        scale={[1, 1, 1]}
        noFlipY={true}
      />
      <DrawingComponent
        // //geometry={nodes.Dibujo03.geometry}
        texturePath={
          sceneDrawings.current[3] !== undefined
            ? apiBase + sceneDrawings.current[3].assetUrl
            : Dibujo
        }
        position={[-4.4742, 0.828, -17.1047]}
        rotation={[0, 0, 0]}
        scale={[1, 1, 1]}
        noFlipY={true}
      />
      <DrawingComponent
        // //geometry={nodes.Dibujo02.geometry}
        texturePath={
          sceneDrawings.current[2] !== undefined
            ? apiBase + sceneDrawings.current[2].assetUrl
            : Dibujo
        }
        position={[-4.4742, 1.8075, -17.1047]}
        rotation={[0, 0, 0]}
        scale={[1, 1, 1]}
        noFlipY={true}
      />
      <DrawingComponent
        // //geometry={nodes.Dibujo01.geometry}
        texturePath={
          sceneDrawings.current[1] !== undefined
            ? apiBase + sceneDrawings.current[1].assetUrl
            : Dibujo
        }
        position={[-4.4742, 2.7869, -17.1047]}
        rotation={[0, 0, 0]}
        scale={[1, 1, 1]}
        noFlipY={true}
      />
      <DrawingComponent
        // //geometry={nodes.Dibujo00.geometry}
        texturePath={
          sceneDrawings.current[0] !== undefined
            ? apiBase + sceneDrawings.current[0].assetUrl
            : Dibujo
        }
        position={[-4.4742, 3.7663, -17.1047]}
        rotation={[0, 0, 0]}
        scale={[1, 1, 1]}
        noFlipY={true}
      />
    </>
  ) : null;
};

export default IbercajagltfDrawings;
