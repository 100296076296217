import React, {useRef, useMemo, useState} from "react";
import {TextureLoader, GammaEncoding} from "three";
import {useLoader} from "react-three-fiber";
import InteractiveMesh from "./InteractiveMesh";
import useStore from "../../store";
import PlayerBehaviour from "../../lib/PlayerBehaviour";

interface TvProps {
  videoName: string;
  previewPath: string;
  videoPath: string;
  geometry: any;
  flipY?: boolean;
  onInteraction?: () => void;
}

const Tv: React.FC<TvProps & JSX.IntrinsicElements["mesh"]> = ({
  videoName,
  previewPath,
  videoPath,
  position,
  flipY = true,
  onInteraction,
  ...props
}) => {
  const previewTexture = useLoader(TextureLoader, previewPath);
  const [videoHasStarted, setVideoHasStarted] = useState(false);
  const isLoading = useRef(false);

  previewTexture.flipY = flipY;

  const video = useMemo(() => {
    const video = document.createElement("video");
    video.src = videoPath;
    video.muted = false;
    video.preload = "auto";
    video.loop = false;
    video.setAttribute("playsinline", "playsinline");
    video.crossOrigin = "anonymous";

    video.addEventListener("canplaythrough", () => {
      isLoading.current = false;
    });

    video.addEventListener("playing", () => {
      if (video.currentTime < 0.1) setVideoHasStarted(true);
      isLoading.current = false;
    });

    video.addEventListener("ended", () => {
      video.currentTime = 0;
      setVideoHasStarted(false);
    });

    return video;
  }, [videoPath]);

  const toggleVideo = () => {
    if (isLoading.current) return;

    if (video.currentTime < 0.05) isLoading.current = true;

    if (video.paused || video.ended) {
      video.play();
    } else {
      video.pause();
    }
  };

  const useModalVideo = useStore<boolean>((state) => state.useModalVideo);
  const setShowModalVideo = useStore((state) => state.setShowModalVideo);
  const playerBehaviour = useStore<PlayerBehaviour>(
    (state) => state.playerBehaviour
  );
  const setModalVideoTitle = useStore((state) => state.setModalVideoTitle);
  const setModalVideoUrl = useStore((state) => state.setModalVideoUrl);

  const toggleModalVideo = () => {
    playerBehaviour.unlockCursor();
    playerBehaviour.stop();
    setModalVideoTitle(videoName);
    setModalVideoUrl(videoPath);
    setShowModalVideo(true);
  };

  return (
    <group position={position}>
      <InteractiveMesh
        {...props}
        visible={!videoHasStarted}
        onInteracted={() => {
          if (onInteraction) {
            onInteraction();
          }

          if (useModalVideo) {
            toggleModalVideo();
          } else {
            toggleVideo();
          }
          // useModalVideo ? toggleModalVideo : toggleVideo;
        }}
      >
        <meshBasicMaterial attach="material" map={previewTexture} />
      </InteractiveMesh>

      <mesh {...props} visible={videoHasStarted}>
        <meshBasicMaterial attach="material">
          <videoTexture
            attach="map"
            args={[video]}
            encoding={GammaEncoding}
            flipY={flipY}
          />
        </meshBasicMaterial>
      </mesh>
    </group>
  );
};

export default Tv;
