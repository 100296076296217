import React from "react";
import {motion} from "framer-motion";
import useStore from "../store";

const Crosshair = () => {
  const activeInteractiveObj = useStore((state) => state.activeInteractiveObj);

  const variants = {
    normal: {
      scale: 1,
      background: "rgba(255, 255, 255, 1)",
      borderColor: "rgba(255, 255, 255, 0)",
    },
    hover: {
      scale: 5,
      background: "rgba(255, 255, 255, 0)",
      borderColor: "rgba(255, 255, 255, 1)",
    },
  };

  return (
    <motion.div
      className="select-none"
      animate={activeInteractiveObj ? "hover" : "normal"}
      variants={variants}
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        width: "0.4rem",
        height: "0.4rem",
        borderWidth: "2px",
        transform: "translate(-50%, -50%)",
        transformOrigin: "50% 50%",
        borderRadius: "50%",
        boxShadow: "0 0 4px rgba(0, 0, 0, 0.5)",
      }}
    />
  );
};

export default Crosshair;
