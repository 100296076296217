import React from "react";

interface BasesTabProps {
  title: string;
  img: string;
  href: string;
  className?: string;
  pClassName?: string;
}

const BasesTab: React.FC<BasesTabProps> = ({
  title,
  img,
  href,
  className = "",
  pClassName = "",
}) => {
  return (
    <a
      href={href}
      target="_blank"
      className={`p-2 pt-4 px-6 border-1 border-black rounded-lg flex flex-col justify-end items-center ${className}`}
      style={{minWidth: "4rem"}}
    >
      <img className="w-16 pb-4" src={img} />
      <p className={`uppercase text-sm ${pClassName}`}>{title}</p>
    </a>
  );
};

export default BasesTab;
