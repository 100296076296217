import React from "react";
import dibujo from "../../assets/img/dibujo.svg";
import Button from "../Button";
import Share from "../share/Share";
import { useScreenshotStore } from "../screenshot/useScreenshotStore";
import { SendEvent } from "../../utils";

interface DownloadLetterProps {
  prevState: () => void;
  endForm: () => void;
  isSubmitting: boolean;
}

const DownloadLetter: React.FC<DownloadLetterProps> = ({
  prevState,
  endForm,
  isSubmitting,

  children: contentHeader,
}) => {
  const { screenshotImg } = useScreenshotStore();

  const LETTEREVENT = "LetterEvent";

  return (
    <>
      <div className="px-12 flex items-center">
        <div className="pr-4 w-1/2">{contentHeader}</div>
        <div className="pr-4 w-1/2 flex flex-col items-center">
          <img src={screenshotImg} />
          <div className="mt-4 flex items-center p-2 rounded-lg text-white bg-red-700 transform transition duration-300 hover:scale-105">
            <a
              href={screenshotImg}
              download="CartaReyes"
              onClick={() => SendEvent(LETTEREVENT, "LetterDownloaded")}
            >
              Descargar
            </a>

            <Share
              img={screenshotImg}
              fileName="CartaReyes"
              onClick={() => SendEvent(LETTEREVENT, "LetterDownloaded")}
            />
          </div>
        </div>
      </div>
      <div className="p-4 px-8 flex justify-between">
        <Button label="Atrás" onClick={prevState} />
        {isSubmitting ? (
          <Button
            label="Enviando..."
            onClick={endForm}
            className="border-ibercaja-gray text-ibercaja-gray "
          />
        ) : (
          <Button label="Finalizar" onClick={endForm} />
        )}
      </div>
    </>
  );
};

export default DownloadLetter;
