import {useMemo, useEffect} from "react";
import {Raycaster, Vector2} from "three";
import {useThree, useFrame} from "react-three-fiber";
import useStore from "../../store";
import {IInteractableMesh} from "./InteractiveMesh";
import useFxSoundStore from "../fxSoundStore";
//import clickAudio from "../../assets/audios/fx/click.mp3";

const CameraRaycaster = () => {
  const interactiveObjs = useStore((state) => state.interactiveObjs);
  const activeInteractiveObj = useStore((state) => state.activeInteractiveObj);
  const setActiveInteractiveObj = useStore(
    (state) => state.setActiveInteractiveObj
  );
  const {camera} = useThree();

  const raycaster = useMemo(() => {
    return new Raycaster();
  }, []);

  const setFxSoundSrc = useFxSoundStore((state) => state.setFxSoundSrc);

  useEffect(() => {
    const onPlayerClick = () => {
      if (!activeInteractiveObj) return;

      console.log("playerClick");
      activeInteractiveObj.onInteracted();

      if (activeInteractiveObj.onInteractionAudio) {
        setFxSoundSrc(activeInteractiveObj.onInteractionAudio);
      }
      // else {
      //   setFxSoundSrc(clickAudio);
      // }
    };

    document.addEventListener("playerClick", onPlayerClick);

    return () => {
      document.removeEventListener("playerClick", onPlayerClick);
    };
  }, [activeInteractiveObj]);

  useFrame(() => {
    raycaster.setFromCamera(new Vector2(0, 0), camera);
    var intersects = raycaster.intersectObjects(interactiveObjs);
    setActiveInteractiveObj(
      intersects.length ? (intersects[0].object as IInteractableMesh) : null
    );
  });

  return null;
};

export default CameraRaycaster;
