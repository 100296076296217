import React, {useState, useEffect} from "react";
import useStore from "../store";
import PlayerBehaviour from "../lib/PlayerBehaviour";
import walk from "../assets/images/walk2.svg";
import OnInteractionModal from "./onInteractionModal/OnInteractionModal";
import MobileDevicesWarning from "./MobileDevicesWarning";
import useOnInteractionModalStore from "./onInteractionModal/useOnInteractionModalStore";
import useScreenshotStore from "./screenshot/useScreenshotStore";
import Screenshot from "./screenshot/Screenshot";
import background from "../assets/images/background.png";
import Crosshair from "./Crosshair";

const MobileUI = () => {
  const playerBehaviour = useStore<PlayerBehaviour>(
    (state) => state.playerBehaviour
  );

  // const [showWarning, setShowWarning] = useState(false);
  const [afterOnBoarding, setAfterOnBoarding] = useState(false);

  const {content} = useOnInteractionModalStore();

  const {screenshotCam} = useScreenshotStore();
  const {showContent} = useOnInteractionModalStore();

  // useEffect(() => {
  //   // The component mounts on start (under OnBoarding), content is null...
  //   // ...but the OnBoardingInstructions haven't been read yet by the user
  //   // After click on Comenzar, content changes to !null -> afterOnBoarding is true
  //   // When user finishes reading Instructions content is null again
  //   if (!content && afterOnBoarding) {
  //     setTimeout(() => setShowWarning(true), 5000);
  //   }
  //   if (content) {
  //     setAfterOnBoarding(true);
  //   }
  // }, [content]);

  return (
    <>
      <OnInteractionModal />

      {/* {showWarning && (
        <MobileDevicesWarning onClose={() => setShowWarning(false)} />
      )} */}

      {/* <img
        className="absolute inset-0 w-full h-full object-cover"
        src={background}
        alt="logo"
      /> */}

      <div className="absolute bottom-0 right-0 mb-8 mr-8 flex items-end">
        <button
          onTouchStart={(ev) => (playerBehaviour.direction[2] = 1)}
          onTouchEnd={(ev) => (playerBehaviour.direction[2] = 0)}
          className="ml-3 w-20 h-20 flex justify-center items-center bg-n16-red rounded-full outline-none bg-ibercaja-blue"
          style={{
            backgroundImage: `url(${walk})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "2.5rem",
          }}
        ></button>
      </div>
      {/* <div className="absolute bottom-0 left-0 mb-8 ml-8 flex items-end bg-tips-gray rounded-full text-white">
        <svg
          className="p-2 w-12 h-12"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          onClick={() => {
            screenshotCam?.takeScreenshot();
            // this.unlockCursor();
            showContent(<Screenshot />, true, false, () => {
              screenshotCam?.clear();
              // this.lockCursor();
            });
          }}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"
          />
        </svg>
      </div> */}

      {/* <button
          onTouchStart={(ev) => (playerBehaviour.direction[2] = 1)}
          onTouchEnd={(ev) => (playerBehaviour.direction[2] = 0)}
          className="ml-3 w-20 h-20 flex justify-center items-center bg-n16-red rounded-full outline-none"
          style={{
            backgroundImage: `url(${joystick})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "5rem",
          }}
        /> */}
      {/* </div> */}
      <Crosshair />
    </>
  );
};

export default MobileUI;
