import React, { useEffect } from "react";
import { useLoader } from "react-three-fiber";
import { TextureLoader } from "three";

interface DrawingProps {
  //geometry: any;
  texturePath: string;
  position?: [number, number, number];
  rotation?: [number, number, number];
  scale?: [number, number, number];
  noFlipY?: boolean;
}

const Drawing: React.FC<DrawingProps> = ({
  texturePath,
  noFlipY,
  ...props
}) => {
  const textureMap = useLoader(TextureLoader, texturePath);

  useEffect(() => {
    textureMap.flipY = !!noFlipY;
  }, [noFlipY, textureMap]);

  return (
    <mesh name="Drawing" {...props}>
      <meshBasicMaterial
        attach="material"
        map={textureMap}
        toneMapped={false}
      />
      <planeBufferGeometry
        attach="geometry"
        args={[1.41 * 0.8, 1 * 0.8, 1 * 0.8]}
      />
    </mesh>
  );
};

export default Drawing;
