import React, {useRef, useEffect} from "react";
import {Mesh} from "three";
import useStore from "../../store";
import IntrinsicElements from "react-three-fiber";

export interface IInteractableMesh extends Mesh {
  onInteracted: () => void;
}

interface InteractiveMeshProps {
  onInteracted: () => void;
}

const InteractiveMesh: React.FC<
  InteractiveMeshProps & JSX.IntrinsicElements["mesh"]
> = ({onInteracted, children, ...props}) => {
  const addInteractiveObj = useStore((state) => state.addInteractiveObj);
  const removeInteractiveObj = useStore((state) => state.removeInteractiveObj);
  const meshRef = useRef<IInteractableMesh>();

  useEffect(() => {
    let meshRefCurrent = meshRef.current;

    if (!meshRefCurrent) return;
    meshRefCurrent.onInteracted = onInteracted;
    addInteractiveObj(meshRefCurrent);

    return () => removeInteractiveObj(meshRefCurrent);
  }, [addInteractiveObj, removeInteractiveObj, onInteracted]);

  return (
    <mesh ref={meshRef} {...props}>
      {children}
    </mesh>
  );
};

export default InteractiveMesh;
