import React from "react";
import dibujo from "../../assets/img/dibujo.svg";
import Button from "../Button";
import Share from "../share/Share";
import { useScreenshotStore } from "../screenshot/useScreenshotStore";

interface DownloadLetterProps {
  prevState: () => void;
  endForm: () => void;
  isSubmitting: boolean;
}

const DownloadLetter: React.FC<DownloadLetterProps> = ({
  prevState,
  endForm,
  isSubmitting,

  children: contentHeader,
}) => {
  const { screenshotImg } = useScreenshotStore();

  return (
    <>
      <div className="space-y-4 h-px-30 py-16">
        <div className="px-8 flex flex-col items-center">
          <div className="w-full flex flex-col items-start">
            {contentHeader}
          </div>
          <img src={screenshotImg} />
          <div className="mt-4 flex items-center p-2 rounded-lg text-white bg-red-700 transform transition duration-300 hover:scale-105">
            <a href={screenshotImg} download="CartaReyes">
              Descargar
            </a>
            <Share img={screenshotImg} fileName="CartaReyes" />
          </div>
        </div>
        <div className="p-4 px-8 flex justify-between">
          <Button label="Atrás" onClick={prevState} />
          {isSubmitting ? (
            <Button
              label="Enviando..."
              onClick={endForm}
              className="border-ibercaja-gray  text-ibercaja-gray "
            />
          ) : (
            <Button label="Finalizar" onClick={endForm} />
          )}
        </div>
      </div>
    </>
  );
};

export default DownloadLetter;
