import create from "zustand";

interface IFxSoundStore {
  // isFxSoundPlaying: boolean;
  fxSoundSrc: string;
  // setIsPlayingFxSound: (b: boolean) => void;
  setFxSoundSrc: (src: string) => void;
}

const [useFxSoundStore] = create<IFxSoundStore>((set) => ({
  // isFxSoundPlaying: false,
  fxSoundSrc: "",
  // setIsPlayingFxSound: (b: boolean) => {
  //   set(() => ({ isFxSoundPlaying: b }));
  // },
  setFxSoundSrc: (src: string) => set(() => ({ fxSoundSrc: src })),
}));

export default useFxSoundStore;
