import React, { useEffect } from "react";
import useAmbientSoundStore from "./ambientSoundStore";

const AmbientSound: React.FC = () => {
  const ambientSoundSrc = useAmbientSoundStore((state) => state.ambientSoundSrc);

  useEffect(() => {
    if (!ambientSoundSrc) return;
    const audio = new Audio(ambientSoundSrc);
    audio.volume = 1;
    audio.loop = true;
    audio.play();
  }, [ambientSoundSrc]);

  return null;
};

export default AmbientSound;
