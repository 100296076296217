import React, {useState} from "react";
import {isMobile} from "../utils";
import useStore from "../store";
import {motion, AnimatePresence} from "framer-motion";
import logo from "../assets/textures/logo.png";
import arrows from "../assets/images/mover_teclas.svg";
import mouse from "../assets/images/mouse.svg";
import touchswipe from "../assets/images/touchswipe.svg";
import walk from "../assets/images/walk2.svg";
import bg from "../assets/images/onboarding-bg.jpg";
import Loading from "./Loading";
import LoadingScene from "./LoadingScene";

interface OnBoardingProps {
  onStart: () => void;
}

const Onboarding: React.FC<OnBoardingProps> = ({onStart}) => {
  const isSceneLoaded = useStore<boolean>((state) => state.isSceneLoaded);

  const mobile = isMobile();

  const [showOnBoarding, setShowOnBoarding] = useState(true);

  return (
    <AnimatePresence>
      {showOnBoarding && (
        <motion.div
          initial={{opacity: 1}}
          animate={{opacity: 1}}
          exit={{opacity: 0}}
          className="absolute inset-0 z-20 flex flex-col justify-center bg-no-repeat bg-center bg-cover"
          style={{backgroundImage: `url(${bg})`}}
        >
          <div className="relative z-10 w-full">
            <img className="w-32 md:w-56 mx-auto" src={logo} alt="Logo" />
          </div>

          <div className="-mt-8 relative w-full p-8 md:p-12 pt-8 text-white bg-black bg-opacity-75">
            <div className="mt-8 md:mt-12 flex justify-center">
              <div className="flex flex-col items-center">
                <img
                  className="h-20 md:h-24"
                  src={mobile ? touchswipe : arrows}
                  alt="Arrows"
                />
                <p className="mt-4 text-sm md:text-xl text-center md:text-left">
                  {mobile
                    ? "Rota para descubrir el entorno"
                    : "Usa las flechas para navegar por la sala"}
                </p>
              </div>
              <div className="ml-12 flex flex-col items-center">
                <img
                  className="h-20 md:h-24"
                  src={mobile ? walk : mouse}
                  alt="Mouse"
                />
                <p className="mt-4 text-sm md:text-xl text-center md:text-left">
                  {mobile
                    ? "Muévete y explora nuestra sala"
                    : "Apunta e interactúa con el ratón"}
                </p>
              </div>
            </div>
          </div>
          <div className="flex justify-center">
            <button
              /*onClick={() =>
                signIn(mail, onFetch, onAuthorized, onUnauthorized, onError)
              }
              disabled={state === "NoData" || !isSceneLoaded}
              className={`mt-${
                state === "Unauthorized" || state === "" ? "6" : "16"
              } flex justify-center px-8 py-4 uppercase text-lg md:text-xl text-white rounded-full select-none outline-none appearance-none font-black ${
                state === "NoData" || !isSceneLoaded
                  ? "bg-gray-400 text-gray-500"
                  : "bg-porcinnova"
              }`}*/
              onClick={() => {
                onStart();
                setShowOnBoarding(false);
              }}
              disabled={!isSceneLoaded}
              className={`flex justify-center px-8 py-4 mt-6 uppercase text-lg md:text-xl text-white rounded-full select-none outline-none appearance-none font-black ${
                isSceneLoaded
                  ? "bg-blue-800 text-white"
                  : "bg-gray-600 text-white"
              }`}
            >
              {isSceneLoaded ? "Comenzar" : "Cargando..."}
            </button>
          </div>
          {!isSceneLoaded && (
            <div className="flex">
              <Loading />
            </div>
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Onboarding;
