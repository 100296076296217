import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
// import loadingAdoquines from "../assets/images/loading_adoquines.svg";
// import loadingBaturros from "../assets/images/loading_baturros.svg";
// import loadingPidgeon from "../assets/images/loading_paloma.svg";
// import useStore from "../stores/store";
// import {shuffleArray} from "../utils";
import Loading from "./LoadingOfrenda";
import { MathUtils } from "three";

interface LoadingSceneProps {
  onLoaded?: () => void;
}

const LoadingScene: React.FC<LoadingSceneProps> = ({ onLoaded }) => {
  const [isSceneLoaded, setIsSceneLoaded] = useState(false);
  //const isSceneLoaded = useStore<boolean>((state) => state.isSceneLoaded);
  //   const shuffledFacts = useMemo(() => shuffleArray(facts), []);
  //   const [currentFact, setCurrentFact] = useState(0);

  //   const message = useMemo(
  //     () => messages[Math.floor(Math.random() * messages.length)],
  //     []
  //   );

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isSceneLoaded) {
        //console.log("asdf");
        setIsSceneLoaded(true);
        if (onLoaded) onLoaded();
      }
    }, 10000 + Math.random() * 500);

    return () => clearInterval(interval);
  }, []);

  return !isSceneLoaded ? (
    <div className="absolute z-20 inset-0 flex flex-col justify-center items-center bg-black">
      {/* <motion.img
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        className="w-56 md:w-64"
        src={message.img}
        alt="Cargando"
      />
      <motion.h2
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        className="mt-4 text-2xl md:text-3xl text-white"
      >
        {t(message.text) + "..."}
      </motion.h2> */}

      <div className="mt-5">
        <Loading />
      </div>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="absolute bottom-0 left-0 right-0 flex justify-center items-center text-ofrenda-blue-light"
      >
        <div className="px-10 md:p-0 md:w-1/2 h-40 md:h-40">
          {/* <h3 className="text-xl md:text-2xl">{t("did_you_know")}...?</h3> */}
          <p className="mt-2 text-sm md:text-base text-white text-center">
            {/* {t(shuffledFacts[currentFact])} */}
            Cargando la experiencia 3D #NavidadIbercaja, el proceso puede tardar
            unos segundos.
          </p>
        </div>
      </motion.div>
    </div>
  ) : null;
};

export default LoadingScene;
