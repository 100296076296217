import React from "react";
import {isMobile} from "../utils";

interface DataIconProps {
  svg: JSX.Element;
  isActive: boolean;
  label: string;
}
const blue = "#0062AE"; // ibercaja-blue
const gray = "#999"; // ibercaja-gray

const DataIcon: React.FC<DataIconProps> = ({svg, isActive, label}) => {
  const iconProps = {
    className: isMobile() ? "w-4" : "w-6",
    fillColor: isActive ? blue : gray,
  };
  return (
    <div className="flex flex-col items-center">
      {React.cloneElement(svg, iconProps)}
      <p
        className={`p-1 w-5/6 uppercase text-${
          isMobile() ? "3xs" : "2xs"
        } text-center text-ibercaja-${isActive ? "blue" : "gray"} `}
      >
        {label}
      </p>
    </div>
  );
};

export default DataIcon;
