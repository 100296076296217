import React from "react";
import ReactModal from "react-modal";
import useOnInteractionModalStore from "./useOnInteractionModalStore";

const OnInteractionModal = () => {
  const {
    content,
    useModalCloseButton,
    fullScreen,
    onClose,
    showContent: setContent,
  } = useOnInteractionModalStore();

  return (
    <ReactModal
      overlayClassName="bg-black absolute inset-0 bg-opacity-50 flex flex-col justify-center items-center"
      className={`${
        fullScreen ? "" : "m-6"
        // } bg-white border-none flex flex-col items-center md:w-5/6 lg:w-3/6 overflow-y-auto`}
      } bg-white border-none flex flex-col items-center overflow-y-auto max-w-6xl`}
      isOpen={content !== null}
    >
      {useModalCloseButton ? (
        <div className="relative w-full">
          <svg
            className="absolute top-0 right-0 m-4 p-1 w-8 rounded-full text-black hover:text-white hover:bg-black "
            viewBox="0 0 20 20"
            fill="currentColor"
            onClick={() => {
              setContent(null);
              if (onClose) onClose();
            }}
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
          {content}
        </div>
      ) : (
        <div className="relative ">{content}</div>
      )}
    </ReactModal>
  );
};

export default OnInteractionModal;
