import create from "zustand";
import Screenshot from "../screenshot/Screenshot";

interface IUseOnInteractionModalStore {
  content: any;
  useModalCloseButton: boolean;
  fullScreen: boolean;
  onClose: () => void;
  showContent: (
    content: any,
    useModalCloseCloseButton?: boolean,
    fullScreen?: boolean,
    onClose?: () => void
  ) => void;
  // showScreenshot: () => void;
}

export const [useOnInteractionModalStore, apiOnInteractionModalStore] = create<
  IUseOnInteractionModalStore
>((set) => ({
  content: null,
  useModalCloseButton: true,
  fullScreen: false,
  onClose: () => {},
  showContent: (
    content: any,
    useModalCloseButton?: boolean,
    fullScreen?: boolean,
    onClose?: () => void
  ) => set({content, useModalCloseButton, fullScreen, onClose}),
}));

export default useOnInteractionModalStore;
