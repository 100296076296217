import create from "zustand";
import ScreenshotCam from "./ScreenshotCam";

interface IUseScreenshotStore {
  screenshotCam: ScreenshotCam;
  screenshotImg: string;
}

export const [useScreenshotStore, apiScreenshotStore] = create<
  IUseScreenshotStore
>(() => ({
  screenshotCam: new ScreenshotCam(),
  screenshotImg: "",
}));

export default useScreenshotStore;
