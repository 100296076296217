import React from "react";

interface Download2SVG {
  className?: string;
  fillColor?: string;
}

const Download2SVG: React.FC<Download2SVG> = ({
  className = "",
  fillColor = "#999",
}) => {
  return (
    <svg className={`${className}`} viewBox="0 0 27.19 27.2">
      <g data-name="Capa 2">
        <g data-name="Capa 1" fill={fillColor}>
          <path d="M6.13 12.7a.86.86 0 00.25.6l7.2 7.07 7.25-7.12a.85.85 0 000-1.2.86.86 0 00-1.21 0l-6 6.05-6-6a.85.85 0 00-1.2 0 .87.87 0 00-.29.6z" />
          <path d="M12.76.85v17.73a.85.85 0 101.69 0V.85a.85.85 0 10-1.69 0z" />
          <path
            d="M27.19 14.75V25.5a1.7 1.7 0 01-1.7 1.7H1.7A1.7 1.7 0 010 25.5V14.75a.85.85 0 01.84-.85.84.84 0 01.86.85v9.71a1 1 0 001 1h21.73a1 1 0 001-1v-9.71a.85.85 0 01.86-.85.85.85 0 01.9.85z"
            fillRule="evenodd"
          />
        </g>
      </g>
    </svg>
  );
};

export default Download2SVG;
