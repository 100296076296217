import React from "react";
import Button from "../../components/Button";
import letter from "../../assets/img/carta.svg";
import ReactPlayer from "react-player";
import vidMelchor from "../../assets/videos/MELCHOR.mp4";
import vidGaspar from "../../assets/videos/GASPAR.mp4";
import vidBaltasar from "../../assets/videos/BALTASAR.mp4";

interface FinishDrawingProps {
  wiseman: string;
  closePanel: () => void;
}

const FinishDrawin: React.FC<FinishDrawingProps> = ({
  wiseman,
  closePanel,
  children: contentHeader,
}) => {
  return (
    <div className="px-12 py-4 flex flex-col items-center">
      <img className="p-4 w-20" src={letter} />
      <h2 className="pb-4">Carta enviada</h2>
      <h3 className="pb-4 uppercase">Gracias por participar</h3>
      <p className="px-8 pb-4">
        Tu carta está de camino… ¡Has elegido a {wiseman}, descubre el mensaje
        que tiene para ti!
      </p>
      {/* <Button label="Continuar" onClick={closePanel} /> */}
      {wiseman === "Melchor" ? (
        <ReactPlayer
          className="py-4"
          playsinline
          controls
          playing
          url={vidMelchor}
        />
      ) : wiseman === "Gaspar" ? (
        <ReactPlayer
          className="py-4"
          playsinline
          controls
          playing
          url={vidGaspar}
        />
      ) : (
        // wiseman === "Baltasar"
        <ReactPlayer
          className="py-4"
          playsinline
          controls
          playing
          url={vidBaltasar}
        />
      )}
    </div>
  );
};

export default FinishDrawin;
