import React from "react";

interface DataSVGProps {
  className?: string;
  fillColor?: string;
}

const DataSVG: React.FC<DataSVGProps> = ({
  className = "",
  fillColor = "#999",
}) => {
  return (
    <svg className={`${className}`} viewBox="0 0 27.25 27.22">
      <g data-name="Capa 2">
        <g data-name="Capa 1" fill={fillColor}>
          <path d="M.86 27.22a.82.82 0 01-.6-.22.85.85 0 01-.26-.9c.06-.1 1.9-6.1 2.27-7.46a.94.94 0 01.22-.39L19.31 1.44A4.9 4.9 0 0122.59 0a4.32 4.32 0 013 1.34 4.57 4.57 0 011.62 3.2 4.7 4.7 0 01-1.51 3.34c-1 1-16.24 16.11-16.89 16.75a.88.88 0 01-.36.21c-1 .31-7.26 2.32-7.32 2.34a.84.84 0 01-.27.04zm3-7.9c-.32 1.15-1.28 4-1.82 5.81 1.79-.58 4.71-1.59 5.7-1.89 1.64-1.61 15.78-15.6 16.73-16.57a3.09 3.09 0 001.05-2.09 2.9 2.9 0 00-1.08-2 2.67 2.67 0 00-1.85-.86 3.28 3.28 0 00-2.08.94c-.45.46-14.94 14.96-16.64 16.66z" />
          <path d="M8.44 24.57a.87.87 0 01-.86-.76 5.08 5.08 0 00-1.4-2.61 5 5 0 00-2.71-1.54.87.87 0 01-.75-1 .85.85 0 01.94-.76A6.8 6.8 0 017.4 20a6.92 6.92 0 011.9 3.62.88.88 0 01-.75 1zM23.79 9a.87.87 0 01-.86-.76 5 5 0 00-1.4-2.61 5.13 5.13 0 00-2.71-1.55.87.87 0 01.18-1.7 6.78 6.78 0 013.74 2.05 6.92 6.92 0 011.9 3.62.88.88 0 01-.75 1z" />
        </g>
      </g>
    </svg>
  );
};

export default DataSVG;
