import { Renderer, Camera, Scene } from "three";
import { apiScreenshotStore } from "./useScreenshotStore";
//import logo from "../../assets/images/torreLogo.png";

class ScreenshotCam {
  private domElement: HTMLCanvasElement | null = null;
  private renderer: Renderer | null = null;
  private camera: Camera | null = null;
  private scene: Scene | null = null;

  init(
    domElement: HTMLCanvasElement,
    renderer: Renderer,
    camera: Camera,
    scene: Scene
  ) {
    this.domElement = domElement;
    this.renderer = renderer;
    this.camera = camera;
    this.scene = scene;
  }

  clear() {
    apiScreenshotStore.setState({ screenshotImg: "" });
  }

  takeScreenshot() {
    if (
      this.domElement !== null &&
      this.renderer !== null &&
      this.camera !== null &&
      this.scene !== null
    ) {
      // Rendering the scene just before taking the screenshot to
      // avoid white screen, read more:
      // 1) White screenshot is a recurring issue,
      //    several threads listed in the 1st answer:
      //    https://stackoverflow.com/a/45223017
      // 2) Explanation on canvas' buffers in the 2nd answer
      //    of this thread (taken from the previous answer):
      //    https://stackoverflow.com/a/32641456
      this.renderer.render(this.scene, this.camera);

      // webgl context doesn't have a getImageData() method,
      // using toDataURL() instead
      const strScreenshot = this.domElement.toDataURL();

      const auxCanvas = document.createElement("canvas");
      auxCanvas.width = this.domElement.width;
      auxCanvas.height = this.domElement.height;

      let imgScreenshot = new Image();
      let imgLogo = new Image();

      imgScreenshot.onload = () => {
        const ctx = auxCanvas.getContext("2d");
        ctx?.drawImage(imgScreenshot, 0, 0);

        const dx = 25;
        const dy = 10;
        const dw = imgLogo.width * 0.05;
        const dh = imgLogo.height * 0.05;
        ctx?.drawImage(imgLogo, dx, dy, dw, dh);

        apiScreenshotStore.setState({ screenshotImg: auxCanvas.toDataURL() });
      };

      imgLogo.onload = () => {
        imgScreenshot.src = strScreenshot;
      };

      //imgLogo.src = logo;
    } else {
      console.warn(
        "No screenshot was taken. Make sure you init the screenshot cam in the scene's useEfect with a renderer, a camera and a scene."
      );
    }
  }
}

export default ScreenshotCam;
