import create from "zustand";
import { AudioListener } from "three";
import PlayerBehaviour from "./lib/PlayerBehaviour";
import { IInteractableMesh } from "./components/three/InteractiveMesh";

export const audioListener = new AudioListener();

const [useStore] = create((set) => ({
  useModalVideo: false,
  setUseModalVideo: (value: boolean) => set(() => ({ useModalVideo: value })),
  platformName: "",
  setPlatformName: (value: string) => set(() => ({ platformName: value })),
  platformProduct: "",
  setPlatformProduct: (value: string) =>
    set(() => ({ platformProduct: value })),
  platformOsFamily: "",
  setPlatformOsFamily: (value: string) =>
    set(() => ({ platformOsFamily: value })),
  platformOsVersion: "",
  setPlatformOsVersion: (value: string) =>
    set(() => ({ platformOsVersion: value })),
  showModalVideo: false,
  setShowModalVideo: (value: boolean) => set(() => ({ showModalVideo: value })),
  showModal: false,
  toggleModal: () => set((state) => ({ showModal: !state.showModal })),
  modalVideoTitle: "",
  setModalVideoTitle: (value: string) =>
    set(() => ({ modalVideoTitle: value })),
  modalVideoUrl: "",
  setModalVideoUrl: (value: string) => set(() => ({ modalVideoUrl: value })),

  showOnboarding: true,
  toggleShowOnboarding: () =>
    set((state) => ({ showOnboarding: !state.showOnboarding })),
  audioListener: new AudioListener(),
  playerBehaviour: new PlayerBehaviour(),
  interactiveObjs: [],
  addInteractiveObj: (obj: any) =>
    set((state) => ({
      interactiveObjs: [obj, ...state.interactiveObjs],
    })),
  removeInteractiveObj: (obj: any) =>
    set((state) => ({
      interactiveObjs: state.interactiveObjs.filter((o: any) => o !== obj),
    })),
  activeInteractiveObj: null,
  setActiveInteractiveObj: (obj: IInteractableMesh | null) =>
    set(() => ({ activeInteractiveObj: obj })),
  isSceneLoaded: false,
  sceneHasLoaded: () => set(() => ({ isSceneLoaded: true })),
}));

export default useStore;
