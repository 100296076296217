import React, { useEffect } from "react";
import { useLoader, useThree } from "react-three-fiber";
import { CubeTextureLoader, GammaEncoding } from "three";

import px from "../../assets/textures/cubemap/cubemap_px.jpg";
import nx from "../../assets/textures/cubemap/cubemap_nx.jpg";
import py from "../../assets/textures/cubemap/cubemap_py.jpg";
import ny from "../../assets/textures/cubemap/cubemap_ny.jpg";
import pz from "../../assets/textures/cubemap/cubemap_pz.jpg";
import nz from "../../assets/textures/cubemap/cubemap_nz.jpg";

const Cubemap: React.FC = () => {
  // @ts-ignore
  const [envMap] = useLoader(CubeTextureLoader, [[px, nx, py, ny, pz, nz]]);

  const { scene } = useThree();

  useEffect(() => {
    envMap.encoding = GammaEncoding;
    scene.background = envMap;
    scene.environment = envMap;
  }, [envMap, scene]);

  return null;
};

export default Cubemap;
