/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF, GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { useLoader } from "react-three-fiber";
import Poster from "./Poster";
import PlaceHolderImg from "../../assets/img/dibujo_placeholder.jpg";

type GLTFResult = GLTF & {
  nodes: {
    POSTER_EMPRESA_03: THREE.Mesh;
    POSTER_EMPRESA_04: THREE.Mesh;
    POSTER_EMPRESA_05: THREE.Mesh;
    POSTER_EMPRESA_02: THREE.Mesh;
    POSTER_EMPRESA_01: THREE.Mesh;
    POSTER_EMPRESA_08: THREE.Mesh;
    POSTER_EMPRESA_09: THREE.Mesh;
    POSTER_EMPRESA_10: THREE.Mesh;
    POSTER_EMPRESA_07: THREE.Mesh;
    POSTER_EMPRESA_06: THREE.Mesh;
  };
  materials: {
    ["POSTER_PAT_MAT.001"]: THREE.MeshStandardMaterial;
  };
};

export default function Model({ ...props }: JSX.IntrinsicElements["group"]) {
  const group = useRef<THREE.Group>();
  const { nodes, materials } = useLoader(
    GLTFLoader,
    "gltf/Pantallas/pantallas.gltf"
  ) as GLTFResult;
  return (
    <group ref={group} {...props} dispose={null}>
      <Poster
        geometry={nodes.POSTER_EMPRESA_03.geometry}
        position={[0, 3.087053, -17.110201]}
        rotation={[Math.PI / 2, 0, 0]}
        texturePath={`assets/img/ganadores/1.jpg`}
        noFlipY={false}
      />
      <Poster
        geometry={nodes.POSTER_EMPRESA_04.geometry}
        position={[2.05, 3.087053, -17.110201]}
        rotation={[Math.PI / 2, 0, 0]}
        texturePath={`assets/img/ganadores/2.jpg`}
        noFlipY={false}
      />
      <Poster
        geometry={nodes.POSTER_EMPRESA_05.geometry}
        position={[4.1, 3.087053, -17.110201]}
        rotation={[Math.PI / 2, 0, 0]}
        texturePath={`assets/img/ganadores/3.jpg`}
        noFlipY={false}
      />
      <Poster
        geometry={nodes.POSTER_EMPRESA_02.geometry}
        position={[-2.05, 3.087053, -17.110201]}
        rotation={[Math.PI / 2, 0, 0]}
        texturePath={`assets/img/ganadores/4.jpg`}
        noFlipY={false}
      />
      <Poster
        geometry={nodes.POSTER_EMPRESA_01.geometry}
        position={[-4.1, 3.087053, -17.110201]}
        rotation={[Math.PI / 2, 0, 0]}
        texturePath={`assets/img/ganadores/5.jpg`}
        noFlipY={false}
      />
      <Poster
        geometry={nodes.POSTER_EMPRESA_08.geometry}
        position={[0, 1.338353, -17.110201]}
        rotation={[Math.PI / 2, 0, 0]}
        texturePath={`assets/img/ganadores/6.jpg`}
        noFlipY={false}
      />
      <Poster
        geometry={nodes.POSTER_EMPRESA_09.geometry}
        position={[2.05, 1.338353, -17.110201]}
        rotation={[Math.PI / 2, 0, 0]}
        texturePath={`assets/img/ganadores/7.jpg`}
        noFlipY={false}
      />
      <Poster
        geometry={nodes.POSTER_EMPRESA_10.geometry}
        position={[4.1, 1.338353, -17.110201]}
        rotation={[Math.PI / 2, 0, 0]}
        texturePath={`assets/img/ganadores/8.jpg`}
        noFlipY={false}
      />
      <Poster
        geometry={nodes.POSTER_EMPRESA_07.geometry}
        position={[-2.05, 1.338353, -17.110201]}
        rotation={[Math.PI / 2, 0, 0]}
        texturePath={`assets/img/ganadores/9.jpg`}
        noFlipY={false}
      />
      <Poster
        geometry={nodes.POSTER_EMPRESA_06.geometry}
        position={[-4.1, 1.338353, -17.110201]}
        rotation={[Math.PI / 2, 0, 0]}
        texturePath={`assets/img/ganadores/10.jpg`}
        noFlipY={false}
      />
    </group>
  );
}
