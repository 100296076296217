import React from "react";
//import Button from "../../components/Button";
import letter from "../../assets/img/carta.svg";
//import vidChristmas from "../../assets/videos/Christmas.mp4";
//import ReactPlayer from "react-player";
import playImg from "../../assets/img/video.svg";
import BasesTab from "../bases/BasesTab";

interface FinishDrawingProps {
  closePanel: () => void;
}

const FinishDrawin: React.FC<FinishDrawingProps> = ({
  closePanel,
  children: contentHeader,
}) => {
  return (
    <div className="px-12 py-4 flex flex-col items-center">
      <img className="p-4 w-20" src={letter} />
      <h2 className="pb-4">Deseo enviado</h2>
      <h3 className="pb-4 uppercase">Gracias por participar</h3>
      <p className="pb-4 text-center">
        Y ahora que has enviado tu deseo, ¡descubre nuestras Felicitaciones de
        Navidad!
      </p>
      {/* <Button label="Continuar" onClick={closePanel} /> */}
      <div className="pb-4 w-full flex justify-evenly">
        <BasesTab
          title="Ver felicitación Ibercaja"
          href="/Christmas.mp4"
          img={playImg}
          pClassName="w-5/6 text-center"
        />
        {/* <BasesTab
          title="Ver felicitación Fundación Ibercaja"
          href="/Felicitacion.mp4"
          img={playImg}
          pClassName="w-4/6 text-center"
        /> */}
      </div>
      <p className="text-xs px-8 pb-4">
        Descárgate la felicitación para compartirla con quien quieras haciendo
        click en el botón derecho del ratón y pulsando en “Guardar video como….”
      </p>
    </div>
  );
};

export default FinishDrawin;
