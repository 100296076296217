import React, {Suspense, useState, useEffect, useRef} from "react";
import {
  checkCurrentPlatformName,
  checkCurrentPlatformOsFamily,
  checkCurrentPlatformOsVersion,
  checkCurrentPlatformProduct,
  isMobile,
  isProblematicIphoneVersion,
} from "./utils";
import Modal from "react-modal";
import useOnInteractionModalStore from "./components/onInteractionModal/useOnInteractionModalStore";
import Scene from "./components/three/Scene";
import DesktopUI from "./components/DesktopUI";
import MobileUI from "./components/MobileUI";
import Onboarding from "./components/Onboarding";
import OnBoardingInstructions from "./components/OnBoardingInstructions";
import OnBoardingInstructionsMobile from "./components/OnBoardingInstructionsMobile";
import AmbientSound from "./components/AmbientSound";
import useStore from "./store";
import UploadDrawingPanel from "./drawings/UploadDrawingPanel";
import UploadDrawingPanelMobile from "./drawings/UploadDrawingPanelMobile";
import SendWiseMenLetterPanel from "./components/wiseMen/SendWiseMenLetterPanel";
import SendWiseMenLetterPanelMobile from "./components/wiseMen/SendWiseMenLetterPanelMobile";
import MakeWishesPanel from "./components/wishes/MakeWishesPanel";
import MakeWishesPanelMobile from "./components/wishes/MakeWishesPanelMobile";
import BasesPanel from "./components/bases/BasesPanel";
import BasesPanelMobile from "./components/bases/BasesPanelMobile";
import GaleryPanel from "./components/galery/GaleryPanel";
import GaleryPanelMobile from "./components/galery/GaleryPanelMobile";
import ImageText from "./components/ImageText";
import {fetchDrawings} from "./drawings/drawings";
import {createIndexSignature} from "typescript";
import PlayerBehaviour from "./lib/PlayerBehaviour";
import ModalVideo from "./components/ModalVideo";
import CartaReyesMagos from "./components/CartaReyesMagos";
import LoadingScene from "./components/LoadingScene";

Modal.setAppElement("#root");

function App() {
  const {showContent} = useOnInteractionModalStore();

  const streamingToday = useStore<boolean>((state) => state.streamingToday);
  const smartsuppBtnDivRef = useRef<HTMLDivElement>();
  const smartsuppGnrlDivRef = useRef<HTMLDivElement>();

  const platformName = useStore<string>((state) => state.platformName);
  const platformProduct = useStore<string>((state) => state.platformProduct);
  const platformOsFamily = useStore<string>((state) => state.platformOsFamily);
  const platformOsVersion = useStore<string>(
    (state) => state.platformOsVersion
  );
  const setPlatformName = useStore((state) => state.setPlatformName);
  const setPlatformProduct = useStore((state) => state.setPlatformProduct);
  const setPlatformOsFamily = useStore((state) => state.setPlatformOsFamily);
  const setPlatformOsVersion = useStore((state) => state.setPlatformOsVersion);
  const setUseModalVideo = useStore((state) => state.setUseModalVideo);

  useEffect(() => {
    setPlatformName(checkCurrentPlatformName());

    setPlatformProduct(checkCurrentPlatformProduct());

    setPlatformOsFamily(checkCurrentPlatformOsFamily());

    setPlatformOsVersion(checkCurrentPlatformOsVersion());
  }, [
    setPlatformName,
    setPlatformOsFamily,
    setPlatformOsVersion,
    setPlatformProduct,
  ]);

  useEffect(() => {
    if (platformName === "" || platformOsFamily === "") return;

    setUseModalVideo(
      (platformOsFamily.includes("iOS") &&
        isProblematicIphoneVersion(platformOsVersion)) ||
        (platformName.includes("Safari") && platformOsFamily.includes("OS X"))
    );

    //console.log((platformOsFamily.includes("iOS") || platformName.includes("Safari")))
  }, [
    platformName,
    platformOsFamily,
    platformOsVersion,
    platformProduct,
    setUseModalVideo,
  ]);

  // useEffect(() => {
  //   // showContent(
  //   //   // isMobile() ? <MakeWishesPanelMobile /> : <MakeWishesPanel />,
  //   //   // isMobile() ? (
  //   //   //   <SendWiseMenLetterPanelMobile />
  //   //   // ) : (
  //   //   //   <SendWiseMenLetterPanel />
  //   //   // ),
  //   //   // isMobile() ? <BasesPanelMobile /> : <BasesPanel />,
  //   //   // isMobile() ? (
  //   //   //   <GaleryPanelMobile cols={2} rows={4} />
  //   //   // ) : (
  //   //   //   <GaleryPanel cols={5} rows={6} />
  //   //   // ),
  //   //   // <GaleryPanel cols={5} rows={6} />,
  //   //   true,
  //   //   false,
  //   //   () => console.log("bye!")
  //   // );
  //   //   //showContent(<ImageText />, true, false, () => console.log("bye!"));
  //   //   // fetchDrawings().then((drawings) => {
  //   //   //   drawings.forEach((drawing, index) => {
  //   //   //     // console.log(index);
  //   //   //     // console.log(drawing);
  //   //   //     // console.log(drawing.createdOn);
  //   //   //     // console.log(drawing.file);
  //   //   //   });
  //   //   // });
  // }, []);

  return (
    // <>{isMobile() ? <MobileUI /> : <DesktopUI />}</>
    <>
      <div className="absolute inset-0">
        <Suspense fallback={<h1>Cargando...</h1>}>
          <Scene />

          <OnBoardingMngr />

          {isMobile() ? <MobileUI /> : <DesktopUI />}

          <AmbientSound />
        </Suspense>
      </div>
    </>
  );
}

const OnBoardingMngr = () => {
  const [showOnboarding, setShowOnBoarding] = useState(true);
  const toggleShowOnboarding = useStore((state) => state.toggleShowOnboarding);

  const [showLoadingScreen, setShowLoadingScreen] = useState(false);

  const playerBehaviour = useStore<PlayerBehaviour>(
    (state) => state.playerBehaviour
  );

  const [state, setState] = useState("onboarding");

  const toggleOnboarding = () => {
    setState("loading");
    //toggleShowOnboarding();
    //setShowOnBoarding(false);
    //setShowLoadingScreen(true);
    //playerBehaviour.lockCursor();
  };

  const toggleLoadingScreen = () => {
    setState("loaded");
    toggleShowOnboarding();
    //setShowLoadingScreen(true);
    playerBehaviour.lockCursor();
  };

  const {showContent} = useOnInteractionModalStore();

  // useEffect(() => {
  //   showContent(<CartaReyesMagos />, false, false);
  // }, []);

  return (
    <>
      {/* <DesktopUI /> */}
      <ModalVideo />
      {/* {showOnboarding ? (
        <Onboarding
          onStart={() => {
            toggleOnboarding();
            //   if (isMobile()) {
            //     showContent(<OnBoardingInstructionsMobile />, false, true);
            //   } else {
            //     showContent(<OnBoardingInstructions />, false);
            //   }
            //
          }}
        />
      ) : showLoadingScreen ? (
        <LoadingScene onLoaded={() => toggleLoadingScreen()} />
      ) : null} */}
      {state === "onboarding" ? (
        <Onboarding
          onStart={() => {
            toggleOnboarding();
            //   if (isMobile()) {
            //     showContent(<OnBoardingInstructionsMobile />, false, true);
            //   } else {
            //     showContent(<OnBoardingInstructions />, false);
            //   }
            //
          }}
        />
      ) : state === "loading" ? (
        <LoadingScene onLoaded={() => toggleLoadingScreen()} />
      ) : null}
    </>
  );
};

export default App;
