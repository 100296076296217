import create from "zustand";

interface IAmbientSoundStore {
  ambientSoundSrc: string;
  setAmbientSoundSrc: (src: string) => void;
}

const [useAmbientSoundStore] = create<IAmbientSoundStore>((set) => ({
  ambientSoundSrc: "",
  setAmbientSoundSrc: (src: string) => set(() => ({ ambientSoundSrc: src })),
}));

export default useAmbientSoundStore;
