import React, { useState } from "react";
import vamos from "../../assets/img/vamos.svg";
import ibercaja from "../../assets/img/ibercaja.svg";
import Icon from "../../drawings/Icon"; // "./Icon";
import DataSVG from "../svg/DataSVG";
import DownloadSVG from "../svg/DownloadSVG";
import Download2SVG from "../svg/Download2SVG";
import Button from "../Button";
import FillLetter from "./FillLetter";
import ChooseGift from "../../drawings/ChooseGift";
import ContentHeader from "../../drawings/ContentHeader";
import DownloadLetter from "./DownloadLetter";
import FinishLetter from "./FinishLetter";
import { useScreenshotStore } from "../screenshot/useScreenshotStore";
import { useOnInteractionModalStore } from "../onInteractionModal/useOnInteractionModalStore";
import { SendEvent } from "../../utils";

interface HeaderProps {
  state: State;
}

const Header: React.FC<HeaderProps> = ({ state }) => {
  return (
    <div className="pt-10 flex justify-center items-start">
      <img className="absolute top-0 pt-10 left-0 pl-0 w-40" src={ibercaja} />
      {/* Rename : isActive -> isHighlighted !! */}
      <Icon
        svg={<DataSVG />}
        isActive={state === State.DATA}
        label="Escribe tu carta"
      />

      {/* <div className="w-12 h-1" style={{backgroundColor: gray}} /> */}
      <Icon
        svg={<Download2SVG />}
        isActive={state === State.DOWNLOAD}
        label="¡Guárdala!"
      />
      {/* <p>{state}</p> */}
    </div>
  );
};

interface FooterProps {
  state: State;
  setState: React.Dispatch<React.SetStateAction<State>>;
  form: Form;
}

const Footer: React.FC<FooterProps> = ({ state, setState }) => {
  // const changeState = (values: string[], nextState: () => void) => {
  //   // [  "",   "a",   "b",   ""]; // [    "x",     "a",     "b",     "y"];

  //   const areEmpty = values.map((v) => v === "");
  //   // [true, false, false, true]; // ["false", "false", "false", "false"];

  //   const isAnyEmpty = areEmpty.reduce((prev, curr) => prev || curr, false);
  //   // true;                       // false;

  //   const areAllFilled = !isAnyEmpty;
  //   // false;                      // true;

  //   if (areAllFilled) nextState();
  // };

  return (
    <div className="p-4 px-8 flex justify-between">
      {state === State.DATA ? (
        <>
          <img className="w-40" src={vamos} />
          <Button type="submit" label="Siguiente" />
          {/* <Button type="submit" label="Siguiente" onClick={nextState} /> */}
        </>
      ) : state === State.DOWNLOAD ? (
        <>
          <Button label="Atrás" />
          {/* <Button label="Atrás" onClick={prevState} /> */}
          <Button label="Finalizar" />
          {/* <Button label="Finalizar" onClick={() => console.log("finalizar")} /> */}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

enum State {
  DATA,
  DOWNLOAD,
  FINISH,
}

interface Form {
  wiseman: string;
  name: string;
  message: string;
  mail: string;
}

const SendWiseMenLetterPanel: React.FC = () => {
  const [state, setState] = useState<State>(State.DATA);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const nextState = () => setState((s) => s + 1);
  const prevState = () => setState((s) => s - 1);

  const [form, setForm] = useState<Form>({
    wiseman: "",
    name: "",
    message: "",
    mail: "",
  });

  const setName = (name: string) => setForm((f) => ({ ...f, name }));
  const setMessage = (message: string) => setForm((f) => ({ ...f, message }));
  const setWiseman = (wiseman: string) => setForm((f) => ({ ...f, wiseman }));
  const setMail = (mail: string) => setForm((f) => ({ ...f, mail }));

  const { screenshotImg } = useScreenshotStore();

  const LETTEREVENT = "LetterEvent";

  const endForm = () => {
    // https://www.pepipost.com/tutorials/how-to-send-emails-with-javascript/
    //setIsSubmitting(true);

    // console.log(form.mail, form.message);

    // @ts-ignore
    // window.Email.send({
    //   // SecureToken: "f9ddeea6-102f-483a-8807-27c97073697c",
    //   SecureToken: "10d7b6d9-d342-44ae-bc73-ad128287b013",
    //   To: form.mail,
    //   From: "espaciovirtualibercaja@gmail.es",
    //   Subject: "Tu Carta a los Reyes Magos",
    //   Body: form.message,
    //   Attachments: [
    //     {
    //       name: "carta.png",
    //       data:
    //         // "https://aprendelibvrefiles.blob.core.windows.net/aprendelibvre-container/course/creacion_de_sitios_web/image/imgscursoweb-01_xl.png",
    //         /**  */
    //         /** canvas.toDataURL() */
    //         /** "data:image/png;base64,iVBORw0KGgoAAAANSUhEgDz3/Km+vQGsoVNxX=" */
    //         /** window.location.origin + Dibujo */
    //         screenshotImg,
    //     },
    //   ],
    // }).then((message: string) => {
    //   console.log(message);
    //   // console.log(screenshotImg);
    //   setIsSubmitting(false);
    //   nextState();
    // });
    nextState();
    SendEvent(LETTEREVENT, "LetterFinished");
  };

  const { showContent: setContent } = useOnInteractionModalStore();

  const content: { [k in State]: JSX.Element } = {
    [State.DATA]: (
      <FillLetter
        wiseman={form.wiseman}
        name={form.name}
        message={form.message}
        mail={form.mail}
        setWiseman={setWiseman}
        setName={setName}
        setMessage={setMessage}
        setMail={setMail}
        nextState={nextState}
        prevState={prevState}
      >
        <ContentHeader n={1} t="Carta a los Reyes" st="Escribe tu mensaje" />
      </FillLetter>
    ),

    [State.DOWNLOAD]: (
      <DownloadLetter
        endForm={endForm}
        prevState={prevState}
        isSubmitting={isSubmitting}
      >
        <ContentHeader n={2} t="Carta a los Reyes" st="Compártela" />
      </DownloadLetter>
    ),

    [State.FINISH]: (
      <FinishLetter
        wiseman={form.wiseman}
        closePanel={() => {
          setContent(null);
        }}
      />
    ),
  };

  return (
    <>
      {state !== State.FINISH && <Header state={state} />}
      {content[state]}
    </>
  );
};

export default SendWiseMenLetterPanel;
