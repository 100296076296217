import {Camera, Vector3, Euler} from "three";
import {audioListener} from "../store";

class PlayerBehaviour {
  direction: [number, number, number] = [0, 0, 0];
  private baseSpeed = 0;
  private sprintSpeed = 0;
  private speed = 0;
  private camera: Camera | null = null;
  private isLocked = false;
  private euler = new Euler(0, 0, 0, "YXZ");
  private lastTouchX = 0;
  private lastTouchY = 0;
  private lookSensitivity = 0;
  private domElement: HTMLElement | null = null;
  private playerClickEvent = new Event("playerClick");
  private isMobile = false;
  private isStop = false;

  init(
    baseSpeed: number,
    camera: Camera,
    domElement: HTMLElement,
    isMobile: boolean
  ) {
    this.baseSpeed = baseSpeed;
    this.sprintSpeed = baseSpeed * 1.5;
    this.speed = baseSpeed;
    this.camera = camera;
    this.domElement = domElement;
    this.isMobile = isMobile;

    if (isMobile) {
      this.lookSensitivity = 0.003;
      this.addMobileListeners();
    } else {
      this.lookSensitivity = 0.001;
      this.addDesktopListeners();
    }
  }

  private setCameraRotation(deltaX: number, deltaY: number) {
    if (!this.camera) return;

    this.euler.setFromQuaternion(this.camera.quaternion);
    this.euler.y -= deltaX * this.lookSensitivity;
    this.euler.x -= deltaY * this.lookSensitivity;
    this.euler.x = Math.max(-Math.PI / 2, Math.min(Math.PI / 2, this.euler.x));

    this.camera.quaternion.setFromEuler(this.euler);
  }

  lockCursor() {
    if (this.isMobile) return;
    document.body.requestPointerLock();
  }

  unlockCursor() {
    if (this.isMobile) return;
    document.exitPointerLock();
  }

  stop() {
    this.isStop = true;
  }

  release() {
    this.direction[0] = 0;
    this.direction[1] = 0;
    this.direction[2] = 0;
    this.speed = this.baseSpeed;
    this.isStop = false;
  }

  private onKeyDown(event: KeyboardEvent) {
    if (!this.isLocked) return;

    switch (event.keyCode) {
      case 38: // up
      case 87: // w
        this.direction[2] = 1;
        break;

      case 37: // left
      case 65: // a
        this.direction[0] = 1;
        break;

      case 40: // down
      case 83: // s
        this.direction[2] = -1;
        break;

      case 39: // right
      case 68: // d
        this.direction[0] = -1;
        break;

      case 16: // shift
        this.speed = this.sprintSpeed;
        break;
    }
  }

  private onKeyUp(event: KeyboardEvent) {
    if (!this.isLocked) return;

    switch (event.keyCode) {
      case 38: // up
      case 87: // w
        this.direction[2] = 0;
        break;

      case 37: // left
      case 65: // a
        this.direction[0] = 0;
        break;

      case 40: // down
      case 83: // s
        this.direction[2] = 0;
        break;

      case 39: // right
      case 68: // d
        this.direction[0] = 0;
        break;

      case 16: // shift
        this.speed = this.baseSpeed;
        break;
    }
  }

  private onClickOrTap() {
    if (!this.isMobile && !this.isLocked) return;
    // HACK https://stackoverflow.com/a/56770254
    audioListener.context.resume();

    document.dispatchEvent(this.playerClickEvent);
  }

  private onMouseMove(event: MouseEvent) {
    if (!this.isLocked) return;
    this.setCameraRotation(event.movementX, event.movementY);
  }

  private onPointerlockChange() {
    this.isLocked = !!document.pointerLockElement;
  }

  private addDesktopListeners() {
    this.domElement?.addEventListener("click", this.lockCursor.bind(this));
    document.addEventListener("click", this.onClickOrTap.bind(this));
    document.addEventListener("mousemove", this.onMouseMove.bind(this));
    document.addEventListener("keydown", this.onKeyDown.bind(this));
    document.addEventListener("keyup", this.onKeyUp.bind(this));
    document.addEventListener(
      "pointerlockchange",
      this.onPointerlockChange.bind(this),
      false
    );
  }

  private onTouchStart(event: TouchEvent) {
    // event.preventDefault();
    this.lastTouchX = event.touches[0].clientX;
    this.lastTouchY = event.touches[0].clientY;
  }

  private onTouchEnd(event: TouchEvent) {
    // event.preventDefault();
  }

  private onTouchMove(event: TouchEvent) {
    event.preventDefault();

    const deltaX = event.touches[0].clientX - this.lastTouchX;
    const deltaY = event.touches[0].clientY - this.lastTouchY;

    this.setCameraRotation(-deltaX, -deltaY);

    this.lastTouchX = event.touches[0].clientX;
    this.lastTouchY = event.touches[0].clientY;
  }

  private addMobileListeners() {
    this.domElement?.addEventListener("click", this.onClickOrTap.bind(this));
    this.domElement?.addEventListener(
      "touchstart",
      this.onTouchStart.bind(this)
    );
    this.domElement?.addEventListener("touchend", this.onTouchEnd.bind(this));
    this.domElement?.addEventListener("touchmove", this.onTouchMove.bind(this));
  }

  getPlayerVelocity() {
    if (!this.camera) return new Vector3(0);

    if (this.isStop) return new Vector3(0);

    const camDir = new Vector3();
    this.camera.getWorldDirection(camDir);

    const camAngle = Math.atan2(camDir.z, camDir.x);
    const vel = new Vector3(
      (Math.cos(camAngle) * this.direction[2] -
        Math.sin(-camAngle) * this.direction[0]) *
        this.speed,
      0,
      (Math.sin(camAngle) * this.direction[2] -
        Math.cos(-camAngle) * this.direction[0]) *
        this.speed
    );

    return vel;
  }
}

export default PlayerBehaviour;
