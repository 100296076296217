import React, { useEffect } from "react";
import { useThree, useFrame } from "react-three-fiber";
import { useBox } from "use-cannon";
import PlayerBehaviour from "../../lib/PlayerBehaviour";
import { isMobile } from "../../utils";
import useStore from "../../store";

interface PlayerControllerProps {
  height: number;
  startPos: [number, number, number];
  startRot: [number, number, number];
}

const PlayerController: React.FC<PlayerControllerProps> = ({
  height,
  startPos,
  startRot,
}) => {
  const { camera, gl } = useThree();
  const [bodyRef, bodyApi] = useBox(() => ({
    mass: 1,
    // args: [0.25, height / 2, 0.25],
    args: [0.25, height, 0.25],
    position: startPos,
    material: { friction: 0 },
    fixedRotation: true,
    allowSleep: false,
  }));
  const playerBehaviour = useStore<PlayerBehaviour>(
    (state) => state.playerBehaviour
  );

  useEffect(() => {
    // playerBehaviour.init(125, camera, gl.domElement, isMobile());
    playerBehaviour.init(125, camera, gl.domElement, isMobile());
  }, [playerBehaviour]);

  useEffect(() => {
    camera.rotation.set(startRot[0], startRot[1], startRot[2]);
  }, [camera, startRot]);

  useEffect(() => {
    bodyApi.position.subscribe((pos) => {
      camera.position.set(pos[0], height, pos[2]);
      // console.log(camera.position);
    });
  }, [bodyApi, camera, height]);

  useFrame((_, delta) => {
    const vel = playerBehaviour.getPlayerVelocity().multiplyScalar(delta);
    bodyApi.velocity.copy(vel);
  });

  return (
    <mesh ref={bodyRef} visible={false}>
      <boxBufferGeometry attach="geometry" args={[0.5, height, 0.5]} />
      <meshBasicMaterial attach="material" color="blue" />
    </mesh>
  );
};

export default PlayerController;
