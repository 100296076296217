import { ErrorMessage, useField } from "formik";
import React from "react";

interface IProfileWisemenField {
  label: string;
  name: string;
}

const ProfileWisemenField: React.FC<IProfileWisemenField> = ({
  label,
  name,
  ...props
}) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.

  const [field, meta] = useField({
    name,
    ...props,
  });

  return (
    <>
      <label
        className="uppercase text-ibercaja-gray text-xs font-bold"
        htmlFor={name}
      >
        {label}
      </label>

      <div className="relative w-full">
        <select
          id={name}
          className={`mt-4 appearance-none border-b-1 border-ibercaja-gray text-ibercaja-gray leading-tight w-full text-sm rounded-none ${
            meta.touched && meta.error ? "border-ibercaja-rojo " : "mb-6"
          }`}
          {...field}
          {...props}
        >
          <option value="-">Escoge a tu rey favorito...</option>
          <option value="Melchor">Melchor</option>
          <option value="Gaspar">Gaspar</option>
          <option value="Baltasar">Baltasar</option>
        </select>
        <div
          className={`pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 ${
            meta.touched && meta.error ? "pt-2" : "mb-3"
          } `}
        >
          <svg
            className="fill-current h-6 w-6 text-ibercaja-gray"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        </div>
      </div>

      {meta.touched && meta.error ? (
        <p className="text-xs mt-2 mb-4 text-ibercaja-rojo self-start">
          <ErrorMessage name={name} />
        </p>
      ) : null}
    </>
  );
};

export default ProfileWisemenField;
