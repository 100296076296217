import React, { useEffect } from "react";
import { useLoader, PointerEvent } from "react-three-fiber";
import { TextureLoader } from "three";

interface PosterProps {
  geometry: any;
  texturePath: string;
  position?: [number, number, number];
  rotation?: [number, number, number];
  scale?: [number, number, number];
  noFlipY?: boolean;
  clickAction?: (e: any) => void;
}

const Poster: React.FC<PosterProps> = ({ texturePath, clickAction, noFlipY, ...props }) => {
  const textureMap = useLoader(TextureLoader, texturePath);

  useEffect(() => {
    textureMap.flipY = !!noFlipY;
  }, [textureMap]);

  return (
    <mesh name="TV_Streaming" {...props} onClick={clickAction}>
      <meshBasicMaterial attach="material" map={textureMap} toneMapped={false} />
    </mesh>
  );
};

export default Poster;
