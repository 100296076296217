import React, { useMemo } from "react";
import { useBox, useCylinder, usePlane } from "use-cannon";
import { MeshBasicMaterial, MathUtils } from "three";

interface WallProps {
  pos: number[];
  rot: number[];
  scale: number[];
  material: MeshBasicMaterial;
}

const Wall: React.FC<WallProps> = ({ pos, rot, scale, material }) => {
  const [bodyRef] = useBox(() => ({
    args: scale.map((n) => n), // extents: [x, y, z]
    position: pos,
    rotation: rot,
    type: "Kinematic",
    material: { friction: 0 },
  }));

  return (
    <mesh ref={bodyRef} material={material} visible={false}>
      <boxBufferGeometry
        attach="geometry"
        args={[scale[0] * 2, scale[1] * 2, scale[2] * 2]}
      />
    </mesh>
  );
};

interface WallCylinderProps {
  pos: number[];
  rot: number[];
  scale: number[];
  radiusTop: number;
  radiusBottom: number;
  height: number;
  numSegments: number;
  material: MeshBasicMaterial;
}

const WallCylinder: React.FC<WallCylinderProps> = ({
  pos,
  rot,
  radiusTop,
  radiusBottom,
  height,
  numSegments,
  scale,
  material,
}) => {
  const [bodyRef] = useCylinder(() => ({
    args: [radiusTop, radiusBottom, height, numSegments] as [
      number,
      number,
      number,
      number
    ], //rhn.map((n) => n), // radiusTop, radiusBottom, height, numSegments
    position: pos,
    rotation: rot,
    type: "Kinematic",
    material: { friction: 0 },
  }));

  return (
    <mesh ref={bodyRef} material={material} visible={false}>
      <cylinderBufferGeometry
        attach="geometry"
        args={[scale[0] * 2, scale[1] * 2, scale[2] * 2]}
      />
    </mesh>
  );
};

const Floor = () => {
  const [bodyRef] = usePlane(() => ({
    rotation: [-Math.PI / 2, 0, 0],
    type: "Kinematic",
    material: { friction: 0 },
  }));

  return (
    <mesh ref={bodyRef} visible={false}>
      <planeBufferGeometry attach="geometry" args={[100, 100]} />
      <meshBasicMaterial attach="material" color="red" />
    </mesh>
  );
};

const IbercajagtlfColliders = () => {
  const wallCollidersData = [
    {
      pos: [0, 0, -17.0],
      rot: [0, 0, 0],
      scale: [12.0, 1.0, 1.0],
    },
    {
      pos: [-5.25, 0, -13.5],
      rot: [0, 0, 0],
      scale: [1.0, 1.0, 8.0],
    },
    {
      pos: [5.25, 0, -16.5],
      rot: [0, 0, 0],
      scale: [1.0, 1.0, 8.0],
    },
    {
      pos: [5.75, 0.0, -9.0],
      rot: [0, MathUtils.DEG2RAD * -30, 0],
      scale: [3, 1.0, 1.0],
    },

    {
      pos: [8.5, 0.0, -4.5],
      rot: [0, MathUtils.DEG2RAD * 15, 0],
      scale: [1, 1.0, 6],
    },
    {
      pos: [10.75, 0.0, -0.75],
      rot: [0, 0, 0],
      scale: [2, 1.0, 4.75],
    },
    {
      pos: [9.25, 0.0, 3.75],
      rot: [0, MathUtils.DEG2RAD * -25, 0],
      scale: [1, 1.0, 4.75],
    },
    {
      pos: [6.13, 0.0, 6.9],
      rot: [0, MathUtils.DEG2RAD * 23.8, 0],
      scale: [2.62, 1.0, 1],
    },
    {
      pos: [2.75, 0.0, 6.75],
      rot: [0, MathUtils.DEG2RAD * 20, 0],
      scale: [5, 1.0, 1],
    },
    {
      pos: [-2.5, 0.0, 6.5],
      rot: [MathUtils.DEG2RAD * 180, MathUtils.DEG2RAD * 20, 0],
      scale: [6.5, 1.0, 1],
    },

    {
      pos: [-9.75, 0.0, 3.75],
      rot: [0, MathUtils.DEG2RAD * 20, 0],
      scale: [1, 1.0, 5],
    },
    {
      pos: [-9.5, 0.0, -0.75],
      rot: [0, MathUtils.DEG2RAD * -30, 0],
      scale: [1, 1.0, 5.5],
    },
    {
      pos: [-6.16, 0.0, -5],
      rot: [0, MathUtils.DEG2RAD * -41, 0],
      scale: [2.45, 1.0, 2.3],
    },
  ];

  const wallMaterial = useMemo(() => {
    return new MeshBasicMaterial({ color: "green" });
  }, []);

  const wallCylinderCollidersData = [
    {
      pos: [0, 0, 0],
      rot: [0, 0, 0],
      radiusTop: 3,
      radiusBottom: 3,
      height: 1,
      numSegments: 8,
      scale: [2.875, 1.0, 2.875],
    },
  ];

  return (
    <>
      {wallCollidersData.map((c, i) => (
        <Wall key={`wall-${i}`} {...c} material={wallMaterial} />
      ))}

      {wallCylinderCollidersData.map((c, i) => (
        <WallCylinder
          key={`wall-cylinder-${i}`}
          {...c}
          material={wallMaterial}
        />
      ))}

      <Floor />
    </>
  );
};

export default IbercajagtlfColliders;
