import React, { useEffect, useState, useRef } from "react";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share";

interface ShareProps {
  img: string;
  fileName?: string;
  onClick?: () => void;
}

const dataURItoBlob = (dataURI: string): Blob => {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  var ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  var blob = new Blob([ab], { type: mimeString });
  return blob;
};

const Share: React.FC<ShareProps> = ({
  img,
  fileName = "download",
  onClick,
}) => {
  // const file = useRef<File>();

  // useEffect(() => {
  //   const dataURItoBlob = async (uri: string): Promise<Blob> => {
  //     const blob = await (await fetch(uri)).blob();
  //     return blob;
  //   };

  //   const blobToFile = async (b: Blob, fileName: string): Promise<File> => {
  //     const file = new File([b], fileName, {type: b.type});
  //     return file;
  //   };

  //   dataURItoBlob(img).then((b) => {
  //     // console.log("blob created");
  //     blobToFile(b, "screenshot.png").then((f) => {
  //       // console.log("file created");
  //       file.current = f;
  //     });
  //   });
  // }, []);

  // console.log("Check navigator.share()");
  // // if (navigator.share === undefined) {
  // // @ts-ignore
  // if (navigator.canShare === undefined) {
  //   console.log("Error: Unsupported feature: navigator.share()");
  // } else {
  //   console.log("Checking: Supported feature: navigator.share()");
  // }

  return (
    <div className="flex justify-end items-center">
      {
        // @ts-ignore
        // navigator.canShare ? (
        //   <svg
        //     viewBox="0 0 20 20"
        //     fill="currentColor"
        //     className="ml-2 p-2 w-10 rounded-full text-ibercaja-gray hover:text-white hover:bg-ibercaja-gray"
        //     onClick={() => {
        //       // share files supported only in > Chrome 75 this means
        //       // on Android we can share images
        //       if (
        //         // @ts-ignore
        //         navigator.canShare({files: [file.current]})
        //       ) {
        //         navigator
        //           .share({
        //             // @ts-ignore
        //             files: [file.current],
        //             title: "La Torre Outlet Zaragoza",
        //             text: "Captura virtual tour de La Torre Outlet Zaragoza",
        //           })
        //           .then(() => console.log("Share was successful."))
        //           .catch((error) =>
        //             console.log("Sharing failed", error.name, error.message)
        //           );
        //       } else {
        //         // share files not supported in iOS this means
        //         // on iOS we only share text
        //         navigator
        //           .share({
        //             title: "La Torre Outlet Zaragoza",
        //             text:
        //               "¡Visita el virtual tour de La Torre Outlet Zaragoza!",
        //           })
        //           .then(() => console.log("Share was successful."))
        //           .catch((error) =>
        //             console.log("Sharing failed", error.name, error.message)
        //           );
        //       }
        //     }}
        //   >
        //     <path d="M15 8a3 3 0 10-2.977-2.63l-4.94 2.47a3 3 0 100 4.319l4.94 2.47a3 3 0 10.895-1.789l-4.94-2.47a3.027 3.027 0 000-.74l4.94-2.47C13.456 7.68 14.19 8 15 8z" />
        //   </svg>
        // ) : (
        // In Desktop we download the image directly
        // conversion to file isn't needed
        <a href={img} download={fileName} onClick={onClick}>
          <svg
            viewBox="0 0 20 20"
            className="ml-2 p-2 w-10 rounded-full "
            style={{ fill: "#ffffff" }}
          >
            <path
              fillRule="evenodd"
              d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </a>
        // )
      }
    </div>
  );
};

export default Share;
