import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Button from "../Button";
import ProfileTextField from "../ProfileTextField";
import ProfileTextAreaField from "../ProfileTextAreaField";
import ProfileWisemenField from "../ProfileWisemenField";
import bgPlaceholder from "../../assets/images/background_letter.jpg";
import { apiScreenshotStore } from "../screenshot/useScreenshotStore";
import { ElementType } from "office-ui-fabric-react";
import vamos from "../../assets/img/vamos.svg";
import { SendEvent } from "../../utils";
// import placeholder_background_wish from "../assets/images/placeholder_background_wish.jpg";

interface FillLetterProps {
  wiseman: string;
  name: string;
  message: string;
  mail: string;
  setWiseman: (t: string) => void;
  setName: (t: string) => void;
  setMessage: (t: string) => void;
  setMail: (t: string) => void;
  prevState: () => void;
  nextState: () => void;
}

const FillLetter: React.FC<FillLetterProps> = ({
  wiseman,
  name,
  message,
  mail,
  setWiseman,
  setName,
  setMessage,
  setMail,
  prevState,
  nextState,
  children: contentHeader,
}) => {
  const REQUERIDO: string = "Este campo es requerido";
  const MAX_CHARS = (n: number): string => {
    return "El campo no puede exceder los " + n + " caracteres";
  };
  const MAIL_INVALIDO = "El campo debe seguir el formato example@ibercaja.es";
  const [linesAreOk, setLinesAreOk] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const canvas = document.createElement("canvas");

  const wiseMenLetterHeight: number = 891;
  const wiseMenLetterWidth: number = 630;

  canvas.width = wiseMenLetterWidth;
  canvas.height = wiseMenLetterHeight;

  const ctx = canvas.getContext("2d");

  function make_image(w: string, m: string, n: string) {
    var background = new Image();
    background.crossOrigin = "anonymous";
    background.src = bgPlaceholder;

    background.onload = function () {
      if (ctx) {
        ctx.drawImage(
          background,
          0,
          0,
          wiseMenLetterWidth,
          wiseMenLetterHeight
          // background.width * (1080 / background.height),
          // 1080
        );
      }
      paintBouquet(w, m, n);
    };
  }

  function paintBouquet(w: string, m: string, n: string) {
    var image = new Image();
    image.crossOrigin = "anonymous";
    image.src = bgPlaceholder;
    // image.src = imascono;

    image.onload = function () {
      // if (ctx) {
      //   ctx.drawImage(
      //     image,
      //     canvas.width - (16 + 62),
      //     canvas.height - (16 + 48),
      //     62,
      //     48
      //     // image.width * (650 / image.height),
      //     // 650
      //   );
      // }

      paint_texts(w, m, n);

      //document.getElementById('myCanvas')?.appendChild(canvas);
    };
  }

  function paint_texts(w: string, m: string, n: string) {
    if (ctx) {
      // console.log("asdf");
      // WISE MEN
      ctx.font = "32px Indie Flower Regular";
      ctx.textAlign = "center";
      ctx.shadowColor = "transparent";
      ctx.shadowBlur = 0;
      // ctx.shadowOffsetX = 0;
      // ctx.shadowOffsetY = 0;
      ctx.fillStyle = "#000000";
      ctx.fillText(w, 259, 222 + 34 + 8, 338);
      // MESSAGE
      ctx.font = "20px Indie Flower Regular";
      ctx.textAlign = "left";
      //ctx.shadowColor = "transparent";
      //ctx.shadowBlur = 0;
      // ctx.shadowOffsetX = 0;
      // ctx.shadowOffsetY = 0;
      //ctx.fillStyle = "#000000";
      printAtWordWrap(ctx, m, 120, 282 + 18, 32, 400);
      // NAME
      ctx.font = "28px Indie Flower Regular";
      ctx.textAlign = "center";
      // ctx.shadowColor = "black";
      // ctx.shadowBlur = 16;
      // ctx.shadowOffsetX = 5;
      // ctx.shadowOffsetY = 5;
      //ctx.fillStyle = "#000000";
      ctx.fillText(n, 371, 744 + 20 + 8, 338);
    }

    apiScreenshotStore.setState({ screenshotImg: canvas.toDataURL() });
  }

  function printAtWordWrap(
    context: any,
    text: string,
    x: number,
    y: number,
    lineHeight: number,
    fitWidth: number
  ) {
    fitWidth = fitWidth || 0;

    if (fitWidth <= 0) {
      context.fillText(text, x, y);
      return -1;
    }

    var lines = text.split("\n");
    var currentLine = -1;
    lines.forEach((line) => {
      currentLine++;
      // line.push(element);
      // console.log("LINE", element);
      var words = line.split(" ");
      var testboll = false;
      var idx = 1;
      while (words.length > 0 && idx <= words.length) {
        // console.log(words);
        // words[0] = words[0] + "\n";
        var str = words.slice(0, idx).join(" ");
        var w = context.measureText(str).width;
        if (w > fitWidth) {
          if (idx === 1) {
            idx = 2;
          }

          context.fillText(
            words.slice(0, idx - 1).join(" "),
            x,
            y + lineHeight * currentLine
          );
          words = words.splice(idx - 1);
          currentLine++;
          //testboll = true;
          idx = 1;
        } else {
          // console.log("IDX", idx);
          idx++;
        }
      }
      // currentLine++;
      // if (testboll) {
      //   currentLine--;
      // }

      if (idx > 0) {
        context.fillText(words.join(" "), x, y + lineHeight * currentLine);
        // if (!testboll) currentLine++;
      }
    });
    console.log(currentLine);
    // 14 lines (starting from 0) is the maximum lines of the letter
    if (currentLine <= 14) {
      setLinesAreOk(true);
    }
    setIsSubmitted(true);
  }

  const LETTEREVENT = "LetterEvent";

  useEffect(() => {
    if (linesAreOk) {
      nextState();
      SendEvent(LETTEREVENT, "LetterComposed");
    }
  }, [linesAreOk]);

  return (
    <Formik
      initialValues={{
        wiseman: wiseman,
        name: name,
        message: message,
        // mail: mail,
      }}
      validationSchema={Yup.object({
        name: Yup.string().required(REQUERIDO).max(35, MAX_CHARS(35)),
        message: Yup.string().required(REQUERIDO),
        // mail: Yup.string().required(REQUERIDO).email(MAIL_INVALIDO),
        wiseman: Yup.string()
          .required(REQUERIDO)
          .oneOf(
            ["Melchor", "Gaspar", "Baltasar"],
            "Debes seleccionar un Rey Mago para continuar."
          ),
      })}
      onSubmit={(values) => {
        // console.log(values);
        setWiseman(values.wiseman);
        setName(values.name);
        setMessage(values.message);
        // setMail(values.mail);

        make_image(values.wiseman, values.message, values.name);

        //setIsSubmitted(true);

        //nextState();

        // setTimeout(() => {}, 1000);
      }}
    >
      {({ isSubmitting }) => (
        <Form className="space-y-4 h-px-30">
          <div className="px-12 flex items-center">
            <div className="pr-4 w-1/2">{contentHeader}</div>
            <div className="pl-4 w-1/2">
              <div className="absolute inset-0 font-indie-flower-regular invisible">
                .
              </div>
              {/* <div className="flex flex-col gap-8 border-2 p-8 w-full">
                <span className="font-lato-black font-bold">
                  A LA ATENCIÓN DE:
                </span>
                <select
                  className="border-2"
                  onChange={(event) => {
                    setWiseman(event.target.value);
                  }}
                  value={wiseman}
                >
                  <option value="" disabled className="hidden">
                    Elige un Rey Mago
                  </option>
                  <option value="Melchor">Melchor</option>
                  <option value="Gaspar">Gaspar</option>
                  <option value="Baltasar">Baltasar</option>
                </select>
              </div> */}
              <ProfileWisemenField label="A la atención de:" name="wiseman" />

              <ProfileTextAreaField
                name="message"
                label="Queridos Reyes Magos:"
                placeholder="Escribe tu carta..."
                showError={isSubmitted && !linesAreOk}
              />
              {/* <span className="font-lato-black font-bold">
                  QUERIDOS REYES MAGOS:
                </span>
                <textarea
                  placeholder="Escribe tu carta"
                  className="border-2 h-full"
                  value={message}
                  onChange={(evt) => {
                    setMessage(evt.target.value.toString());
                  }}
                  style={{resize: "none"}}
                /> */}

              <ProfileTextField
                name="name"
                label="Firmado"
                type="text"
                placeholder="Escribe aquí tu nombre..."
              />
              {/* <ProfileTextField
                name="mail"
                label="Correo del electrónico"
                type="text"
                placeholder="example@ibercaja.es"
              /> */}

              {/* <ProfileTextField
                name="category"
                label="Categoría de edad"
                type="text"
                placeholder="Jaca"
              />
              <ProfileTextField
                name="nif"
                label="Nif del padre/madre empleado"
                type="text"
                placeholder="12345678-X"
              />
              <ProfileTextField
                name="mail"
                label="Correo del electrónico ibercaja del/la padre/madre"
                type="text"
                placeholder="example@ibercaja.es"
              />
              <ProfileBoolField
                name="authorized"
                legal=""
                type="checkbox"
                placeholder="example@ibercaja.es"
              /> */}
            </div>
          </div>
          <div className="p-4 px-8 flex justify-between">
            {/* <Button type="button" label="Atrás" onClick={prevState} /> */}
            <img className="w-40" src={vamos} />
            <Button type="submit" label="Crear carta" />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FillLetter;
