import React from "react";

interface ContentHeaderProps {
  n: number;
  t: string;
  st: string;
  i?: string[];
  l?: string[];
}

const ContentHeader: React.FC<ContentHeaderProps> = ({
  n: stepNumber,
  t: panelTitle,
  st: stepTitle,
  i: instructions = [],
  l: logos = [],
}) => {
  return (
    <div>
      <h3 className="text-ibercaja-rojo text-2xl">{stepNumber}</h3>
      <h4 className="uppercase text-xs">{panelTitle}</h4>
      <h2 className="text-2xl">{stepTitle}</h2>
      {instructions.map((i) => (
        <p className="text-xs pb-2">{i}</p>
      ))}
      <div className="pt-4 flex">
        {logos.map((l) => (
          <img className="px-4 h-10" src={l} />
        ))}
      </div>
    </div>
  );
};

export default ContentHeader;
