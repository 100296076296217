// import { Sky } from "drei";
import React, { Suspense, useEffect } from "react";
import { Canvas } from "react-three-fiber";
// import { FogExp2, MathUtils } from "three";
import { Physics } from "use-cannon";
import useStore from "../../store";
import CameraRaycaster from "./CameraRaycaster";
// import Colliders from "./Colliders";
import Lights from "./Lights";
import Ibercajagltf from "./Ibercajagltf";
import PlayerController from "./PlayerController";
import IbercajagltfColliders from "./IbercajagltfColliders";
import Cubemap from "./Cubemap";
import ColliderGenerated from "./ColliderGenerated";

function SceneFallback() {
  const sceneHasLoaded = useStore((state) => state.sceneHasLoaded);

  // We wait until the fallback is unmounted + 1 second to mark the scene as loaded
  useEffect(() => {
    return () => {
      setTimeout(() => {
        sceneHasLoaded();
      }, 1000);
    };
  }, [sceneHasLoaded]);

  return null;
}

function Scene() {
  const audioListener = useStore((state) => state.audioListener);
  //const pixelRatio = getGpuTier() > 1 ? 1.5 : 1.25;
  const pixelRatio = 1.25;

  return (
    <Canvas
      colorManagement
      shadowMap
      pixelRatio={pixelRatio}
      className="w-full h-full"
      onCreated={({ camera, scene }) => {
        //scene.fog = new FogExp2(0xcaf7ff, 0.005);
        camera.add(audioListener);
      }}
    >
      <Physics>
        <PlayerController
          height={1.7}
          startPos={[0, 0, 3.75]}
          startRot={[0, 0, 0]}
        />

        <IbercajagltfColliders />
      </Physics>

      <CameraRaycaster />

      <Lights />

      {/* @ts-ignore */}
      {/* <Sky distance={4500000000} sunPosition={[-0.5, 1, -1]} /> */}

      <Suspense fallback={<SceneFallback />}>
        <Cubemap />
        <Ibercajagltf />
      </Suspense>

      {/* <Stats /> */}
    </Canvas>
  );
}

export default Scene;
