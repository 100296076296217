import React, { useState } from "react";
import vamos from "../../assets/img/vamos.svg";
import ibercaja from "../../assets/img/ibercaja.svg";
import fb from "../../assets/img/rs_FB.svg";
import ig from "../../assets/img/rs_IG.svg";
import tw from "../../assets/img/rs_TW.svg";
import Icon from "../../drawings/Icon"; // "./Icon";
import DataSVG from "../svg/DataSVG";
import DownloadSVG from "../svg/DownloadSVG";
import Download2SVG from "../svg/Download2SVG";
import Button from "../Button";
import FillWish from "./FillWish";
import ContentHeader from "../../drawings/ContentHeader";
import DownloadWish from "./DownloadWish";
import FinishWish from "./FinishWish";
import { useOnInteractionModalStore } from "../onInteractionModal/useOnInteractionModalStore";
import { SendEvent } from "../../utils";

interface HeaderProps {
  state: State;
}

const Header: React.FC<HeaderProps> = ({ state }) => {
  return (
    <div className="pt-10 flex justify-center items-start">
      <img className="absolute top-0 pt-10 left-0 pl-0 w-40" src={ibercaja} />
      {/* Rename : isActive -> isHighlighted !! */}
      <Icon
        svg={<DataSVG />}
        isActive={state === State.DATA}
        label="Escribe tu mensaje"
      />

      {/* <div className="w-12 h-1" style={{backgroundColor: gray}} /> */}
      <Icon
        svg={<Download2SVG />}
        isActive={state === State.DOWNLOAD}
        label="¡Guárdalo!"
      />
      {/* <p>{state}</p> */}
    </div>
  );
};

interface FooterProps {
  state: State;
  setState: React.Dispatch<React.SetStateAction<State>>;
  form: Form;
}

const Footer: React.FC<FooterProps> = ({ state, setState }) => {
  // const changeState = (values: string[], nextState: () => void) => {
  //   // [  "",   "a",   "b",   ""]; // [    "x",     "a",     "b",     "y"];

  //   const areEmpty = values.map((v) => v === "");
  //   // [true, false, false, true]; // ["false", "false", "false", "false"];

  //   const isAnyEmpty = areEmpty.reduce((prev, curr) => prev || curr, false);
  //   // true;                       // false;

  //   const areAllFilled = !isAnyEmpty;
  //   // false;                      // true;

  //   if (areAllFilled) nextState();
  // };

  return (
    <div className="p-4 px-8 flex justify-between">
      {state === State.DATA ? (
        <>
          <img className="w-40" src={vamos} />
          <Button type="submit" label="Siguiente" />
          {/* <Button type="submit" label="Siguiente" onClick={nextState} /> */}
        </>
      ) : state === State.DOWNLOAD ? (
        <>
          <Button label="Atrás" />
          {/* <Button label="Atrás" onClick={prevState} /> */}
          <Button label="Finalizar" />
          {/* <Button label="Finalizar" onClick={() => console.log("finalizar")} /> */}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

enum State {
  DATA,
  DOWNLOAD,
  FINISH,
}

interface Form {
  name: string;
  wish: string;
}

const MakeWishesPanel: React.FC = () => {
  const [state, setState] = useState<State>(State.DATA);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const nextState = () => setState((s) => s + 1);
  const prevState = () => setState((s) => s - 1);

  const [form, setForm] = useState<Form>({
    name: "",
    wish: "",
  });

  const setName = (name: string) => setForm((f) => ({ ...f, name }));
  const setWish = (wish: string) => setForm((f) => ({ ...f, wish }));

  const WISHEVENT = "WishEvent";

  const endForm = () => {
    nextState();
    SendEvent(WISHEVENT, "WishFinished");
  };

  const { showContent: setContent } = useOnInteractionModalStore();

  const content: { [k in State]: JSX.Element } = {
    [State.DATA]: (
      <FillWish
        name={form.name}
        wish={form.wish}
        setName={setName}
        setWish={setWish}
        nextState={nextState}
        prevState={prevState}
      >
        <ContentHeader n={1} t="Pide un deseo" st="Escribe tu mensaje" />
      </FillWish>
    ),

    [State.DOWNLOAD]: (
      <DownloadWish
        endForm={endForm}
        prevState={prevState}
        isSubmitting={isSubmitting}
      >
        <ContentHeader
          n={2}
          t="Pide un deseo"
          st="Compártelo"
          i={[
            "Descárgate la imagen y comparte tus buenos deseos con tus seres queridos.",
            "También puedes compartirla en Facebook, Instagram y Twitter a través del hashtag #NavidadIbercaja",
          ]}
          // l={[fb, tw, ig]}
        />
      </DownloadWish>
    ),

    [State.FINISH]: (
      <FinishWish
        closePanel={() => {
          setContent(null);
        }}
      />
    ),
  };

  return (
    <>
      {state !== State.FINISH && <Header state={state} />}

      {content[state]}
    </>
  );
};

export default MakeWishesPanel;
