import {MeshBasicMaterial} from "three";
import platform from "platform";
import ReactGA from "react-ga";

export const isMobile = () => {
  return "ontouchstart" in document.documentElement;
};

export const checkCurrentPlatformName = () => {
  //console.log(platform);
  //console.log(platform.name);
  return platform.name;
  //console.log(platform.name?.includes("Chrome") ? "YES" : "NO")
  //console.log(platform.os?.family?.includes("iOS") ? "YES" : "NO")
};

export const checkCurrentPlatformProduct = () => {
  //console.log(platform.product);
  return platform.product != null ? platform.product : "";
  //console.log(platform.name?.includes("Chrome") ? "YES" : "NO")
  //console.log(platform.os?.family?.includes("iOS") ? "YES" : "NO")
};

export const checkCurrentPlatformOsFamily = () => {
  //console.log(platform.os?.family);
  return platform.os?.family;
  //console.log(platform.name?.includes("Chrome") ? "YES" : "NO")
  //console.log(platform.os?.family?.includes("iOS") ? "YES" : "NO")
};

export const checkCurrentPlatformOsVersion = () => {
  //console.log(platform.os?.version);
  return platform.os?.version;
};

export const isAndroid = () => Boolean(navigator.userAgent.match(/Android/i));

export const isIos = () =>
  Boolean(navigator.userAgent.match(/iPhone|iPad|iPod/i));

export const convertToBasicMaterials = (materials: any) => {
  Object.keys(materials).forEach((k) => {
    if (materials[k].map) materials[k].map.anisotropy = 8;

    materials[k] = new MeshBasicMaterial({
      map: materials[k].map,
      color: materials[k].color,
    });
  });
};

export const isProblematicIphoneVersion = (currentVersion: string) => {
  const currentVersionSplitted: string[] = currentVersion.split(".");

  return (
    Number(currentVersionSplitted[0]) < 14 ||
    (Number(currentVersionSplitted[0]) === 14 &&
      Number(currentVersionSplitted[1]) < 2)
  );
};

export const SendEvent = (category: string, label: string) => {
  ReactGA.event({
    category, // Required
    action: "event", // Required
    label,
  });
};
