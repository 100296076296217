import React from "react";
import OnInteractionModal from "./onInteractionModal/OnInteractionModal";
import OnScreenInstructions from "./OnScreenInstructions";
import torreLogo from "../assets/images/torreLogo.png";
import background from "../assets/images/background.png";
import Crosshair from "./Crosshair";
import useOnInteractionModalStore from "./onInteractionModal/useOnInteractionModalStore";
import navImage from "../assets/images/atajos.svg";

const DesktopUI = () => {
  const { content } = useOnInteractionModalStore();

  return (
    <>
      <OnInteractionModal />

      <div
        className="absolute bottom-0 left-0 py-2 px-2 flex flex-col gap-4 rounded-md justify-center items-start bg-no-repeat bg-center bg-cover"
        style={{
          zIndex: 5,
          marginBottom: "3.5rem",
          marginLeft: "1.5rem",
        }}
      >
        <div
          className="flex text-black pt-4 w-full pb-2"
          style={{
            background: "rgba(255, 255, 255, 0.7)",
            backdropFilter: "blur(4px)",
            left: 0,
            right: 0,
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <img className="w-64 m-3" src={navImage} alt="nav" />
        </div>
      </div>
      {/* <img
        className="absolute inset-0 w-full h-full object-cover"
        src={background}
        alt="logo"
      /> */}
      {/* <div className="absolute bottom-0 left-0 right-0 m-8 flex flex-row-reverse justify-between items-end">
      <div className="absolute bottom-0 left-0 right-0 m-8 flex flex-row justify-between items-end">
        {!content && <OnScreenInstructions />}
      </div> */}

      <Crosshair />
    </>
  );
};

export default DesktopUI;
