import React from "react";

interface ButtonProps {
  label: string;
  onClick?: () => void;
  type?: "button" | "submit" | "reset";
  className?: string;
}

const Button: React.FC<ButtonProps> = ({
  label,
  onClick,
  type = "button",
  className,
}) => {
  return (
    <>
      {onClick ? (
        <button
          className={`p-1 px-4 border-1 border-black rounded-md text-black ${className}`}
          onClick={onClick}
          type={type}
        >
          {label}
        </button>
      ) : (
        <button
          className={`p-1 px-4 border-1 border-black rounded-md text-black ${className}`}
          type={type}
        >
          {label}
        </button>
      )}
    </>
  );
};

export default Button;
