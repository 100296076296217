import {ErrorMessage, useField} from "formik";
import React from "react";

interface IProfileTextField {
  label: string;
  name: string;
  type: string;
  placeholder?: string;
}

const ProfileTextField: React.FC<IProfileTextField> = ({
  label,
  name,
  type,
  ...props
}) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.

  const [field, meta] = useField({
    name,
    type,
    ...props,
  });

  return (
    <>
      <label
        className="uppercase text-ibercaja-gray text-xs font-bold"
        htmlFor={name}
      >
        {label}
      </label>

      <input
        className={`mt-2 border-ibercaja-gray border-1 text-ibercaja-gray w-full p-2 text-sm ${
          meta.touched && meta.error ? "border-ibercaja-rojo " : "mb-6"
        }`}
        type={type}
        {...field}
        {...props}
      />

      {meta.touched && meta.error ? (
        <p className="text-xs mt-2 mb-4 text-ibercaja-rojo self-start">
          <ErrorMessage name={name} />
        </p>
      ) : null}
    </>
  );
};

export default ProfileTextField;
