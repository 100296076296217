import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Button from "../Button";
import ProfileTextField from "../ProfileTextField";
import ProfileTextAreaField from "../ProfileTextAreaField";
import ProfileWisemenField from "../ProfileWisemenField";
import bgPlaceholder from "../../assets/images/background_wish.jpg";
import { apiScreenshotStore } from "../screenshot/useScreenshotStore";
import vamos from "../../assets/img/vamos.svg";
import { formProperties } from "office-ui-fabric-react";
import { SendEvent } from "../../utils";
// import placeholder_background_wish from "../assets/images/placeholder_background_wish.jpg";

interface FillWishProps {
  name: string;
  wish: string;
  setName: (t: string) => void;
  setWish: (t: string) => void;
  prevState: () => void;
  nextState: () => void;
}

const FillWish: React.FC<FillWishProps> = ({
  name,
  wish,
  setName,
  setWish,
  prevState,
  nextState,
  children: contentHeader,
}) => {
  const REQUERIDO: string = "Este campo es requerido";
  const MAX_CHARS = (n: number): string => {
    return "El campo no puede exceder los " + n + " caracteres";
  };
  const MAIL_INVALIDO = "El campo debe seguir el formato example@ibercaja.es";
  const [linesAreOk, setLinesAreOk] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const canvas = document.createElement("canvas");

  const christmasTreeWishHeight: number = 800;
  const christmasTreeWishWidth: number = 800;

  canvas.width = christmasTreeWishWidth;
  canvas.height = christmasTreeWishHeight;

  const ctx = canvas.getContext("2d");

  function make_image(n: string, w: string) {
    var background = new Image();
    background.crossOrigin = "anonymous";
    background.src = bgPlaceholder;

    background.onload = function () {
      if (ctx) {
        ctx.drawImage(
          background,
          0,
          0,
          christmasTreeWishWidth,
          christmasTreeWishHeight
          // background.width * (1080 / background.height),
          // 1080
        );
      }
      paintBouquet(n, w);
    };
  }

  function paintBouquet(n: string, w: string) {
    var image = new Image();
    image.crossOrigin = "anonymous";
    image.src = bgPlaceholder;
    // image.src = imascono;

    image.onload = function () {
      // if (ctx) {
      //   ctx.drawImage(
      //     image,
      //     canvas.width - (16 + 62),
      //     canvas.height - (16 + 48),
      //     62,
      //     48
      //     // image.width * (650 / image.height),
      //     // 650
      //   );
      // }

      paint_texts(n, w);

      //document.getElementById('myCanvas')?.appendChild(canvas);
    };
  }

  function paint_texts(n: string, w: string) {
    if (ctx) {
      // MESSAGE
      ctx.font = "32px Indie Flower Regular";
      ctx.textAlign = "left";
      // ctx.shadowColor = "transparent";
      // ctx.shadowBlur = 0;
      // ctx.shadowOffsetX = 0;
      // ctx.shadowOffsetY = 0;
      ctx.fillStyle = "#000000";
      printAtWordWrap(ctx, w, 125 + 10, 148 + 18 + 10, 32, 550);
      // NAME
      ctx.font = "40px Indie Flower Regular";
      ctx.textAlign = "center";
      // ctx.shadowColor = "black";
      // ctx.shadowBlur = 16;
      // ctx.shadowOffsetX = 5;
      // ctx.shadowOffsetY = 5;
      //ctx.fillStyle = "#000000";
      ctx.fillText(n, 400, 578 + 24 + 20, 550);
    }

    apiScreenshotStore.setState({ screenshotImg: canvas.toDataURL() });
  }

  function printAtWordWrap(
    context: any,
    text: string,
    x: number,
    y: number,
    lineHeight: number,
    fitWidth: number
  ) {
    fitWidth = fitWidth || 0;

    if (fitWidth <= 0) {
      context.fillText(text, x, y);
      return -1;
    }

    var lines = text.split("\n");
    var currentLine = -1;
    lines.forEach((line) => {
      currentLine++;
      // line.push(element);
      // console.log("LINE", element);
      var words = line.split(" ");
      var testboll = false;
      var idx = 1;
      while (words.length > 0 && idx <= words.length) {
        // console.log(words);
        // words[0] = words[0] + "\n";
        var str = words.slice(0, idx).join(" ");
        var w = context.measureText(str).width;
        if (w > fitWidth) {
          if (idx === 1) {
            idx = 2;
          }

          context.fillText(
            words.slice(0, idx - 1).join(" "),
            x,
            y + lineHeight * currentLine
          );
          words = words.splice(idx - 1);
          currentLine++;
          //testboll = true;
          idx = 1;
        } else {
          // console.log("IDX", idx);
          idx++;
        }
      }
      // currentLine++;
      // if (testboll) {
      //   currentLine--;
      // }

      if (idx > 0) {
        context.fillText(words.join(" "), x, y + lineHeight * currentLine);
        // if (!testboll) currentLine++;
      }
    });
    console.log(currentLine);
    // 14 lines (starting from 0) is the maximum lines of the letter
    if (currentLine <= 12) {
      setLinesAreOk(true);
    }
    setIsSubmitted(true);
  }

  const WISHEVENT = "WishEvent";

  useEffect(() => {
    if (linesAreOk) {
      nextState();
      SendEvent(WISHEVENT, "WishComposed");
    }
  }, [linesAreOk]);

  return (
    <Formik
      initialValues={{
        name: name,
        wish: wish,
      }}
      validationSchema={Yup.object({
        name: Yup.string().required(REQUERIDO).max(35, MAX_CHARS(35)),
        wish: Yup.string().required(REQUERIDO),
      })}
      onSubmit={(values) => {
        console.log(values);
        setName(values.name);
        setWish(values.wish);

        make_image(values.name, values.wish);

        // nextState();
        // setTimeout(() => {}, 1000);
      }}
    >
      {({ isSubmitting }) => (
        <Form className="space-y-4 h-px-30">
          <div className="px-12 flex items-center">
            <div className="pr-4 w-1/2">{contentHeader}</div>
            <div className="pl-4 w-1/2">
              <div className="absolute inset-0 font-indie-flower-regular invisible">
                .
              </div>

              <ProfileTextField
                name="name"
                label="Nombre"
                type="text"
                placeholder="Escribe aquí tu nombre..."
              />
              <ProfileTextAreaField
                name="wish"
                label="Tu deseo"
                placeholder="Espero que el 2022..."
                showError={isSubmitted && !linesAreOk}
              />

              {/*Deseamos con esperanza. A los deseos se les espera. ¿Otra actitud hacia los deseos?*/}
              {/* <ProfileTextField
                name="category"
                label="Categoría de edad"
                type="text"
                placeholder="Jaca"
              />
              <ProfileTextField
                name="nif"
                label="Nif del padre/madre empleado"
                type="text"
                placeholder="12345678-X"
              />
              <ProfileTextField
                name="mail"
                label="Correo del electrónico ibercaja del/la padre/madre"
                type="text"
                placeholder="example@ibercaja.es"
              />
              <ProfileBoolField
                name="authorized"
                legal=""
                type="checkbox"
                placeholder="example@ibercaja.es"
              /> */}
            </div>
          </div>
          <div className="p-4 px-8 flex justify-between">
            {/* <Button type="button" label="Atrás" onClick={prevState} /> */}
            <img className="w-40" src={vamos} />
            <Button type="submit" label="Continuar" />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FillWish;
