import React, {useRef, useMemo, useState} from "react";
import {TextureLoader, GammaEncoding, Object3D, Color} from "three";
import {useFrame, useLoader, useThree} from "react-three-fiber";
import InteractiveMesh from "./InteractiveMesh";
import useStore from "../../store";
import PlayerBehaviour from "../../lib/PlayerBehaviour";

interface TvProps {
  videoName: string;
  previewPath: string;
  videoPath: string;
  geometry: any;
  flipY?: boolean;
  onInteraction?: () => void;
}

const uniforms = {
  scale: {type: "f", value: 1.2},
  time: {type: "f", value: 0.0},
  // baseColor: { type: "vec3", value: new Color(0x25407f) },
  baseColor: {type: "vec3", value: new Color(0x000000)},
  noise1Color: {type: "vec3", value: new Color(0x3aabff)},
  noise2Color: {type: "vec3", value: new Color(0x85e6f5)},
  fresnelColor: {type: "vec3", value: new Color(0x5cb9ff)},
};

const TvFollow: React.FC<TvProps & JSX.IntrinsicElements["mesh"]> = ({
  videoName,
  previewPath,
  videoPath,
  position,
  flipY = true,
  onInteraction,
  ...props
}) => {
  const previewTexture = useLoader(TextureLoader, previewPath);
  const [videoHasStarted, setVideoHasStarted] = useState(false);
  const isLoading = useRef(false);

  previewTexture.flipY = flipY;

  const video = useMemo(() => {
    const video = document.createElement("video");
    video.src = videoPath;
    video.muted = false;
    video.preload = "auto";
    video.loop = false;
    video.setAttribute("playsinline", "playsinline");
    video.crossOrigin = "anonymous";

    video.addEventListener("canplaythrough", () => {
      isLoading.current = false;
    });

    video.addEventListener("playing", () => {
      if (video.currentTime < 0.1) setVideoHasStarted(true);
      isLoading.current = false;
    });

    video.addEventListener("ended", () => {
      video.currentTime = 0;
      setVideoHasStarted(false);
    });

    return video;
  }, [videoPath]);

  const toggleVideo = () => {
    if (isLoading.current) return;

    if (video.currentTime < 0.05) isLoading.current = true;

    if (video.paused || video.ended) {
      video.play();
    } else {
      video.pause();
    }
  };

  const useModalVideo = useStore<boolean>((state) => state.useModalVideo);
  const setShowModalVideo = useStore((state) => state.setShowModalVideo);
  const playerBehaviour = useStore<PlayerBehaviour>(
    (state) => state.playerBehaviour
  );
  const setModalVideoTitle = useStore((state) => state.setModalVideoTitle);
  const setModalVideoUrl = useStore((state) => state.setModalVideoUrl);

  const toggleModalVideo = () => {
    playerBehaviour.unlockCursor();
    playerBehaviour.stop();
    setModalVideoTitle(videoName);
    setModalVideoUrl(videoPath);
    setShowModalVideo(true);
  };

  const group = useRef<THREE.Group>();

  const videoRef = useRef<Object3D>();

  const {camera} = useThree();

  useFrame((state) => {
    uniforms.time.value = state.clock.elapsedTime * 0.1;
    group.current?.lookAt(camera.position);
  });

  return (
    <group ref={group} position={position}>
      <InteractiveMesh
        {...props}
        visible={!videoHasStarted}
        onInteracted={() => {
          if (onInteraction) {
            onInteraction();
          }

          if (useModalVideo) {
            toggleModalVideo();
          } else {
            toggleVideo();
          }
          // useModalVideo ? toggleModalVideo : toggleVideo;
        }}
      >
        <meshBasicMaterial attach="material" map={previewTexture} />
      </InteractiveMesh>

      <mesh {...props} ref={videoRef} visible={videoHasStarted}>
        <meshBasicMaterial attach="material">
          <videoTexture
            attach="map"
            args={[video]}
            encoding={GammaEncoding}
            flipY={flipY}
          />
        </meshBasicMaterial>
      </mesh>
    </group>
  );
};

export default TvFollow;
