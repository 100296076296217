import React from "react";
//import { MathUtils } from "three";
//import {getGpuTier} from "../../utils";

const Lights: React.FC = () => {
  //const GPUTier = getGpuTier();
  //const castShadows = GPUTier > 1;
  //const shadowMapSize = GPUTier === 2 ? 2046 : 4092;

  return (
    <>
      {/* <directionalLight
        color={0xfcf9d4}
        //castShadow={castShadows}
        position={[0, 100, -40]}
        shadowCameraLeft={-250}
        shadowCameraTop={250}
        shadowCameraRight={250}
        shadowCameraBottom={-250}
        shadowCameraFar={200}
        //shadowMapWidth={shadowMapSize}
        //shadowMapHeight={shadowMapSize}
        // shadowBias={0.0015}
      /> */}

      <hemisphereLight args={[0xf1f1f1, 0x212121, 1.0]} />
      {/* <hemisphereLight args={[0xb5e8fc, 0x513a2a, 1.0]} /> */}
    </>
  );
};

export default Lights;
